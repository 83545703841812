import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component } from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { Project } from 'src/app/models/project';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent extends EditComponent {

  data: User;
  newPassword: boolean = false;
  projects: Project[] = [];

  protected apiSlug = 'User';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
    public auth: AuthService,
  ) {
    super(route, router, api);

    const blank: User = {
      name: '',
      email: '',
      password: '',
      role: 'operator',
      project_id: null
    };

    this.fill(blank);

    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovog korisnika?';

    this.api.allProjects('name', 'asc').subscribe(projects => this.projects = projects);
  }

  onRoleChange() {
    if (this.data.role !== 'operator') {
      this.data.project_id = null;
    }
  }

}
