import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Model } from '../models/model';
import { Observable, Subject } from 'rxjs';
import { Paginated } from '../models/paginated';
import { Project } from '../models/project';
import { Record } from '../models/record';
import { ResponseObservable } from '../models/response-observable';
import { SortDirection } from '@angular/material/sort';
import { TokenResponse } from '../models/token-response';
import { User } from '../models/user';
import { ProjectGroup } from '../models/project-group';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  isLoading: boolean = false;
  response: Subject<ResponseObservable> = new Subject();

  constructor(private http: HttpClient) { }

  // Auth

  login(email: string, password: string): Observable<TokenResponse> {
    return this.http.post(`${environment.api.url}/auth/login`, { email, password }) as Observable<TokenResponse>;
  }

  logout(): Observable<{ message: string }> {
    return this.http.post(`${environment.api.url}/auth/logout`, '') as Observable<{ message: string }>;
  }

  refresh(): Observable<TokenResponse> {
    return this.http.post(`${environment.api.url}/auth/refresh`, '') as Observable<TokenResponse>;
  }

  me(): Observable<User> {
    return this.http.get(`${environment.api.url}/auth/me`) as Observable<User>;
  }

  // Models

  indexModels(page: number, limit: number, column: string, direction: SortDirection): Observable<Paginated> {
    return this.http.get(`${environment.api.url}/models`, { params: { page: page.toString(), limit: limit.toString(), column, direction } }) as Observable<Paginated>;
  }

  allModels(column: string, direction: SortDirection): Observable<Model[]> {
    return this.http.get(`${environment.api.url}/models/all`, { params: { column, direction } }) as Observable<Model[]>;
  }

  allParentModels(column: string, direction: SortDirection): Observable<Model[]> {
    return this.http.get(`${environment.api.url}/models/all-parents`, { params: { column, direction } }) as Observable<Model[]>;
  }

  createModel(model: Model): Observable<{ message: string, data: Model }> {
    return this.http.post(`${environment.api.url}/models`, model) as Observable<{ message: string, data: Model }>;
  }

  readModel(id: string): Observable<Model> {
    return this.http.get(`${environment.api.url}/models/${id}`) as Observable<Model>;
  }

  parentModels(id: string, column: string, direction: SortDirection): Observable<Model[]> {
    return this.http.get(`${environment.api.url}/models/${id}/parents`, { params: { column, direction } }) as Observable<Model[]>;
  }

  updateModel(model: Model): Observable<{ message: string, data: Model }> {
    return this.http.put(`${environment.api.url}/models/${model._id}`, model) as Observable<{ message: string, data: Model }>;
  }

  deleteModel(id: string): Observable<{ message: string }> {
    return this.http.delete(`${environment.api.url}/models/${id}`) as Observable<{ message: string }>;
  }

  // Users

  indexUsers(page: number, limit: number, column: string, direction: SortDirection): Observable<Paginated> {
    return this.http.get(`${environment.api.url}/users`, { params: { page: page.toString(), limit: limit.toString(), column, direction } }) as Observable<Paginated>;
  }

  allUsers(column: string, direction: SortDirection): Observable<User[]> {
    return this.http.get(`${environment.api.url}/users/all`, { params: { column, direction } }) as Observable<User[]>;
  }

  createUser(user: User): Observable<{ message: string, data: User }> {
    return this.http.post(`${environment.api.url}/users`, user) as Observable<{ message: string, data: User }>;
  }

  readUser(id: string): Observable<User> {
    return this.http.get(`${environment.api.url}/users/${id}`) as Observable<User>;
  }

  updateUser(user: User): Observable<{ message: string, data: User }> {
    return this.http.put(`${environment.api.url}/users/${user._id}`, user) as Observable<{ message: string, data: User }>;
  }

  deleteUser(id: string): Observable<{ message: string }> {
    return this.http.delete(`${environment.api.url}/users/${id}`) as Observable<{ message: string }>;
  }

  // Projects

  indexProjects(page: number, limit: number, column: string, direction: SortDirection): Observable<Paginated> {
    return this.http.get(`${environment.api.url}/projects`, { params: { page: page.toString(), limit: limit.toString(), column, direction } }) as Observable<Paginated>;
  }

  allProjects(column: string, direction: SortDirection): Observable<Project[]> {
    return this.http.get(`${environment.api.url}/projects/all`, { params: { column, direction } }) as Observable<Project[]>;
  }

  createProject(project: Project): Observable<{ message: string, data: Project }> {
    return this.http.post(`${environment.api.url}/projects`, project) as Observable<{ message: string, data: Project }>;
  }

  readProject(id: string): Observable<Project> {
    return this.http.get(`${environment.api.url}/projects/${id}`) as Observable<Project>;
  }

  updateProject(project: Project): Observable<{ message: string, data: Project }> {
    const copy = { ...project };
    delete copy.records;
    return this.http.put(`${environment.api.url}/projects/${project._id}`, copy) as Observable<{ message: string, data: Project }>;
  }

  assignRecords(projectId: string, recordIds: string[], modelId:string): Observable<{ message: string }> {
    return this.http.post(`${environment.api.url}/projects/${projectId}/assign`, { record_ids: recordIds, model_id: modelId }) as Observable<{ message: string }>;
  }


  deleteProject(id: string): Observable<{ message: string }> {
    return this.http.delete(`${environment.api.url}/projects/${id}`) as Observable<{ message: string }>;
  }

  // Records

  indexRecords(modelId: string, page: number, limit: number, column: string, direction: SortDirection, opt: object): Observable<Paginated> {
    return this.http.get(`${environment.api.url}/records`, { params: { ...opt, modelId, page: page.toString(), limit: limit.toString(), column, direction } }) as Observable<Paginated>;
  }

  getRecordsData(modelId: string, column: string): Observable<any[]> {
    return this.http.get(`${environment.api.url}/records/data`, { params: { modelId, column } }) as Observable<any[]>;
  }

  getRecordsGeom(projectId: string): Observable<Record[]> {
    return this.http.get(`${environment.api.url}/records/geom`, { params: { projectId } }) as Observable<Record[]>;
  }

  getIntersection(modelId: string, projectId: string, geometry: any): Observable<string[]> {
    return this.http.post(`${environment.api.url}/records/intersection`, { modelId, projectId, geometry }) as Observable<string[]>;
  }

  createRecord(record: Record): Observable<{ message: string, data: Record }> {
    return this.http.post(`${environment.api.url}/records`, record) as Observable<{ message: string, data: Record }>;
  }

  importRecords(modelId: string, fk: string, ok: string, geojson: string): Observable<{ message: string }> {
    return this.http.post(`${environment.api.url}/records/import`, { modelId, fk, ok, geojson }) as Observable<{ message: string }>;
  }

  uploadFile(file: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.api.url}/upload`, formData) as Observable<{ url: string }>;
  }

  readRecord(id: string): Observable<Record> {
    return this.http.get(`${environment.api.url}/records/${id}`) as Observable<Record>;
  }

  readInitialRecord(id: string): Observable<any> {
    return this.http.get(`${environment.api.url}/records/${id}/initial`) as Observable<any>;
  }

  updateRecord(record: Record): Observable<{ message: string, data: Record }> {
    return this.http.put(`${environment.api.url}/records/${record._id}`, record) as Observable<{ message: string, data: Record }>;
  }

  deleteRecord(id: string): Observable<{ message: string }> {
    return this.http.delete(`${environment.api.url}/records/${id}`) as Observable<{ message: string }>;
  }

  // Project groups

  indexProjectGroups(page: number, limit: number, column: string, direction: SortDirection): Observable<Paginated> {
    return this.http.get(`${environment.api.url}/project-groups`, { params: { page: page.toString(), limit: limit.toString(), column, direction } }) as Observable<Paginated>;
  }

  allProjectGroups(column: string, direction: SortDirection): Observable<ProjectGroup[]> {
    return this.http.get(`${environment.api.url}/project-groups/all`, { params: { column, direction } }) as Observable<ProjectGroup[]>;
  }

  createProjectGroup(projectGroup: ProjectGroup): Observable<{ message: string, data: ProjectGroup }> {
    return this.http.post(`${environment.api.url}/project-groups`, projectGroup) as Observable<{ message: string, data: ProjectGroup }>;
  }

  readProjectGroup(id: string): Observable<ProjectGroup> {
    return this.http.get(`${environment.api.url}/project-groups/${id}`) as Observable<ProjectGroup>;
  }

  updateProjectGroup(projectGroup: Project): Observable<{ message: string, data: ProjectGroup }> {
    return this.http.put(`${environment.api.url}/project-groups/${projectGroup._id}`, projectGroup) as Observable<{ message: string, data: ProjectGroup }>;
  }

  deleteProjectGroup(id: string): Observable<{ message: string }> {
    return this.http.delete(`${environment.api.url}/project-groups/${id}`) as Observable<{ message: string }>;
  }

  // Export

  export(modelId: string, columns: string[]): Observable<any> {
    return this.http.post(`${environment.api.url}/export`, { modelId, columns }) as Observable<any>;
  }

}
