<div class="title">
  <h1>Modeli</h1>
  <a mat-fab routerLink="new" color="primary" aria-label="Kreiraj novi model">
    <mat-icon>add</mat-icon>
  </a>
</div>
<div class="container mat-elevation-z1">
  <div class="table-responsive" [class.disabled]="isLoadingResults">
    <table mat-table [dataSource]="data" multiTemplateDataRows matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Naziv modela</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
      <ng-container matColumnDef="is_options_model">
        <th mat-header-cell *matHeaderCellDef>Šifarnik</th>
        <td mat-cell *matCellDef="let row">{{ row.is_options_model ? 'Da' : 'Ne' }}</td>
      </ng-container>
      <ng-container matColumnDef="has_geometry">
        <th mat-header-cell *matHeaderCellDef>Geometrija</th>
        <td mat-cell *matCellDef="let row">{{ row.has_geometry ? 'Da' : 'Ne' }}</td>
      </ng-container>
      <ng-container matColumnDef="parent_id">
        <th mat-header-cell *matHeaderCellDef>Roditelj</th>
        <td mat-cell *matCellDef="let row">{{ row.parent_id ? 'Ne' : 'Da' }}</td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kreiran</th>
        <td mat-cell *matCellDef="let row">{{ row.created_at | amDateFormat:'DD.MM.YYYY u HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ažuriran</th>
        <td mat-cell *matCellDef="let row">{{ row.updated_at | amDateFormat:'DD.MM.YYYY u HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="row-options" [@optionsExpand]="row === expandedRow ? 'expanded' : 'collapsed'">
            <a mat-raised-button color="primary" [routerLink]="row._id">Izmijeni</a>
            <button mat-raised-button color="warn" (click)="deleteRow(row._id)">Obriši</button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="expandedRow = expandedRow === row ? null : row" class="main" [class.expanded]="expandedRow === row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['options']" class="options"></tr>
    </table>
  </div>
  <mat-paginator [length]="resultsLength" pageSize="50" [pageSizeOptions]="limit"></mat-paginator>
</div>
