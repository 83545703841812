import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  working = false;
  adm = environment.adm;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public api: ApiService,
    public store: StoreService,
    private http: HttpClient
  ) {}

  getReport(path: string) {
    this.working = true;

    this.http
      .get(`${environment.api.url}/reports/${path}`)
      .subscribe((response: string) => {
        window.open(response);
        // console.log(response);
        this.working = false;
      });
  }
}
