import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component } from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { ProjectGroup } from 'src/app/models/project-group';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-project-group',
  templateUrl: './project-group.component.html',
  styleUrls: ['./project-group.component.scss'],
})
export class ProjectGroupComponent extends EditComponent {

  data: ProjectGroup;

  protected apiSlug = 'ProjectGroup';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
    public auth: AuthService,
  ) {
    super(route, router, api);

    const blank: ProjectGroup = {
      name: '',
    };

    this.fill(blank);

    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovu grupu?';
  }

}
