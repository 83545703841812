import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlBs extends MatPaginatorIntl {

  public itemsPerPageLabel = 'Broj redova:';
  public nextPageLabel = 'Slijedeća stranica';
  public previousPageLabel = 'Prethodna stranica';

  public getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 od ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} od ${length}`;
  }

}
