import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store.service';
import { Model } from 'src/app/models/model';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  selectedModel: Model;
  columns: { name: string }[];
  errors: any = {};
  errorStateMatcher = new ErrorStateMatcher();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public api: ApiService,
    public store: StoreService
  ) {
    this.errorStateMatcher.isErrorState = (
      control: FormControl,
      form: NgForm
    ): boolean => {
      const name = Object.keys(form.controls).find(
        (name) => form.controls[name] === control
      );
      return !name || this.errors[name];
    };
  }

  onSubmit(e: any) {
    e && e.preventDefault();

    this.api
      .export(
        this.selectedModel._id,
        this.columns.map((c) => c.name)
      )
      .subscribe((response) => {
        const json = JSON.stringify(response);
        const element = document.createElement('a');
        const extension = this.selectedModel.has_geometry ? 'geojson' : 'json';

        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(json)
        );

        element.setAttribute(
          'download',
          `${this.selectedModel.name}.${extension}`
        );
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
  }

  onModelChange() {
    if (this.selectedModel) {
      this.columns = this.selectedModel.columns
        .filter((c) => c.type != 'file')
        .map((c) => {
          return { name: `[${c.name}]` };
        });
    }
  }

  moveColumn(index: number, newIndex: number) {
    this.columns[index] = this.columns.splice(
      newIndex,
      1,
      this.columns[index]
    )[0];
  }
}
