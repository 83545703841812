<div class="title">
  <a mat-icon-button routerLink=".." color="primary" aria-label="Prikaži sve korisnike">
    <mat-icon>list</mat-icon>
  </a>

  <h1 *ngIf="isEdit && data">Korisnik: {{ data.name }}</h1>
  <h1 *ngIf="!isEdit">Novi korisnik</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">

  <!-- name -->
  <mat-form-field>
    <mat-label>Ime i prezime</mat-label>
    <input matInput [(ngModel)]="data.name" type="text" name="name" required [errorStateMatcher]="errorStateMatcher">
    <mat-error *ngIf="errors.name">{{ errors.name[0] }}</mat-error>
  </mat-form-field>

  <!-- email -->
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="data.email" type="email" name="email" required [errorStateMatcher]="errorStateMatcher">
    <mat-error *ngIf="errors.email">{{ errors.email[0] }}</mat-error>
  </mat-form-field>

  <!-- newPassword -->
  <mat-slide-toggle *ngIf="isEdit" [(ngModel)]="newPassword" name="newPassword">Izmijeni lozinku</mat-slide-toggle>

  <!-- password -->
  <mat-form-field *ngIf="!isEdit || newPassword">
    <mat-label>{{ isEdit ? 'Nova lozinka' : 'Lozinka' }}</mat-label>
    <input matInput [(ngModel)]="data.password" type="password" name="password" required [errorStateMatcher]="errorStateMatcher" autocomplete="new-password">
    <mat-hint *ngIf="isEdit">Ostavite prazno ukoliko ne želite mijenjati lozinku</mat-hint>
    <mat-error *ngIf="errors.password">{{ errors.password[0] }}</mat-error>
  </mat-form-field>

  <!-- role -->
  <mat-form-field>
    <mat-label>Korisnička uloga</mat-label>
    <mat-select [(ngModel)]="data.role" (ngModelChange)="onRoleChange()" name="role" required [errorStateMatcher]="errorStateMatcher">
      <mat-option *ngIf="auth.isRole('admin') || data._id !== auth.getUserId()" value="operator">Operator</mat-option>
      <mat-option *ngIf="auth.isRole('admin') || data._id === auth.getUserId()" value="moderator">Moderator</mat-option>
      <mat-option *ngIf="auth.isRole('admin')" value="admin">Administrator</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.role">{{ errors.role[0] }}</mat-error>
  </mat-form-field>

  <!-- project_id -->
  <mat-form-field *ngIf="auth.isRole('admin') && data.role === 'operator'">
    <mat-label>Projekt</mat-label>
    <mat-select [(ngModel)]="data.project_id" name="project_id" [errorStateMatcher]="errorStateMatcher">
      <mat-option [value]="null">(bez projekta)</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project._id">{{ project.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.project_id">{{ errors.project_id[0] }}</mat-error>
  </mat-form-field>

  <div class="buttons">
    <button mat-raised-button [disabled]="api.isLoading" color="primary">{{ isEdit ? 'Snimi' : 'Kreiraj' }}</button>
    <button *ngIf="isEdit" mat-raised-button (click)="delete()" [disabled]="api.isLoading" color="warn" type="button">Obriši</button>
  </div>
</form>
