import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Model } from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  models: Model[] = [];

  constructor(private api: ApiService) {
    this.api.response.subscribe(r => {
      if (r.path.startsWith('/models') && ['POST', 'PUT', 'DELETE'].includes(r.method)) {
        this.api.allModels('name', 'asc').subscribe(models => this.models = models);
      }
    });

    this.retrieveModels();
  }

  private retrieveModels() {
    this.api.allModels('name', 'asc').subscribe(models => this.models = models);
  }

  getModel(id: string): Model {
    return this.models.find(m => m._id === id);
  }

}
