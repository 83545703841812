import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Column } from 'src/app/models/column';
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Model } from 'src/app/models/model';
import { Project } from 'src/app/models/project';
import { Record } from 'src/app/models/record';
import { Router, ActivatedRoute } from '@angular/router';

import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import {
  defaults as defaultInteractions,
  Draw,
  Modify,
  Snap,
} from 'ol/interaction';
import { GeoJSON } from 'ol/format';
import { Map, View, Geolocation, Feature, Overlay } from 'ol';
import { OSM, BingMaps, Vector as VectorSource } from 'ol/source';
import { Point, Polygon, MultiPolygon } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { getArea, getLength } from 'ol/sphere';
import { unByKey } from 'ol/Observable';
import { NotifierService } from 'angular-notifier';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
})
export class RecordComponent
  extends EditComponent
  implements AfterViewInit, OnDestroy {
  modelId: string = this.route.snapshot.paramMap.get('modelId');
  isEdit: boolean = !this.modelId;
  parentId: string = this.route.snapshot.queryParamMap.get('parentId');
  data: Record;
  initialData: Record;
  model: Model = null;
  projects: Project[] = [];
  options: { [modelId: string]: any[] } = {};
  backRouterLink: string[] = this.isEdit ? [] : ['..'];
  map: Map;
  draw: any;
  drawSource: any;
  drawing: boolean = false;
  snap: any;
  obj: any = new VectorLayer({
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: [0, 0, 255, 0.75] }),
        stroke: new Stroke({
          color: [255, 255, 255, 0.75],
          width: 2,
        }),
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.5)',
      }),
      stroke: new Stroke({
        color: 'white',
        width: 2,
      }),
    }),
  });
  centered: boolean = false;
  geolocation: any;
  positionFeature: any;
  view: any;
  imagerySet: string = 'AerialWithLabelsOnDemand';
  imagerySets = [
    { name: 'Bing Ortofoto', value: 'AerialWithLabelsOnDemand' },
    { name: 'Bing Karta', value: 'RoadOnDemand' },
  ];
  area: string;
  boundary: any = new VectorLayer({
    style: new Style({
      fill: new Fill({
        color: 'transparent',
      }),
      stroke: new Stroke({
        color: 'red',
        width: 3,
      }),
    }),
  });
  prev: any;
  next: any;

  @ViewChild('map') mapEl: ElementRef;

  protected apiSlug = 'Record';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
    public auth: AuthService,
    public notifier: NotifierService
  ) {
    super(route, router, api);

    const blank: Record = {
      model_id: this.modelId,
      project_id: null,
      parent_id: this.parentId,
      children: [],
      data: {},
      geometry: null,
      comments: [],
      comment: '',
      status: 'open',
      author_id: null,
    };

    this.fill(blank);

    this.deleteConfirmationMessage =
      'Da li ste sigurni da želite obrisati ovaj zapis i sve njegove veze?';

    if (!this.isEdit) {
      this.api
        .readModel(this.modelId)
        .subscribe((model) => this.applyModelToData(model));
    }

    this.api.allProjects('name', 'asc').subscribe((projects) => {
      this.projects = projects;

      if (this.auth.isRole('operator')) {
        this.setBoundary();
      }
    });
  }

  loaded() {
    this.backRouterLink = ['..', 'model', this.data.model_id];
    this.api
      .readModel(this.data.model_id)
      .subscribe((model) => this.applyModelToData(model));

    if (this.initialData === undefined) {
      this.initialData = null;
      this.api
        .readInitialRecord(this.id)
        .subscribe((r) => (this.initialData = r.new_data));
    }
  }

  submitAndCreateNew() {
    this.errors = {};
    if (this.isEdit) {
      this.api.updateRecord(this.data).subscribe(
        (r: any) =>
          this.router.navigate(['/records', 'model', this.model._id, 'new'], {
            queryParams: this.route.snapshot.queryParams,
          }),
        (e: HttpErrorResponse) => (this.errors = e.error.errors)
      );
    } else {
      this.api.createRecord(this.data).subscribe(
        (r: any) =>
          this.model.columns.forEach(
            (column) => (this.data.data[column.name] = '')
          ),
        (e: HttpErrorResponse) => (this.errors = e.error.errors)
      );
    }
  }

  updateStatus(status: 'open' | 'review' | 'closed') {
    let mssg: string;

    switch (status) {
      case 'open':
        mssg = 'Da li ste sigurni da želite zapis vratiti na doradu?';
        break;
      case 'review':
        mssg = 'Da li ste sigurni da želite zapis poslati na pregled?';
        break;
      case 'closed':
        mssg = 'Da li ste sigurni da želite zaključit zapis?';
        break;
    }

    if (confirm(mssg)) {
      this.data.status = status;
      this.errors = {};
      this.api.updateRecord(this.data).subscribe(
        () => this.navigateToList(),
        (e: HttpErrorResponse) => (this.errors = e.error.errors)
      );
    }
  }

  addChild(model: Model) {
    this.api.updateRecord(this.data).subscribe(
      (r: any) =>
        this.router.navigate(['/records', 'model', model._id, 'new'], {
          queryParams: { parentId: r.data._id },
        }),
      (e: HttpErrorResponse) => (this.errors = e.error.errors)
    );
  }

  editChild(record: Record) {
    this.api.updateRecord(this.data).subscribe(
      (r: any) =>
        this.router.navigate(['/records', record._id], {
          queryParams: { parentId: r.data._id },
        }),
      (e: HttpErrorResponse) => (this.errors = e.error.errors)
    );
  }

  sortChildren(columnName: string, direction: 'asc' | 'desc') {
    this.data.children = this.data.children.sort((a, b) => {
      if (a.data[columnName] == b.data[columnName]) {
        return 0;
      }

      if (direction == 'asc') {
        return a.data[columnName] < b.data[columnName] ? -1 : 1;
      } else {
        return a.data[columnName] > b.data[columnName] ? -1 : 1;
      }
    });
  }

  isRequired(column: Column): boolean {
    return column.validation.some((v) => v.rule.startsWith('required'));
  }

  isLocked(column: Column): boolean {
    return (
      this.auth.isRole('operator') &&
      this.data.author_id !== this.auth.getUserId() &&
      column.is_locked &&
      this.isEdit
    );
  }

  isVisible(column: Column): boolean {
    let visible = true;
    if (column.validation) {
      const excludeIf = column.validation
        .filter((v) => v.rule.startsWith('exclude_if:'))
        .map((v) => v.rule.split(/[:,]/));
      const excludeUnless = column.validation
        .filter((v) => v.rule.startsWith('exclude_unless:'))
        .map((v) => v.rule.split(/[:,]/));

      excludeIf.forEach((p) => {
        const v1 = this.data.data[p[1].replace(/^data\./, '')];
        let v2: any = p[2];
        if (typeof v1 === 'boolean') {
          v2 = !!(v2 === 'true');
        } else if (typeof v1 === 'number') {
          v2 = Number(v2);
        }

        visible = p.length === 3 && v1 === v2 ? false : visible;
      });

      excludeUnless.forEach((p) => {
        const v1 = this.data.data[p[1].replace(/^data\./, '')];
        let v2: any = p[2];
        if (typeof v1 === 'boolean') {
          v2 = !!(v2 === 'true');
        } else if (typeof v1 === 'number') {
          v2 = Number(v2);
        }

        visible = p.length === 3 && v1 !== v2 ? false : visible;
      });
    }

    return visible;
  }

  navigateToList() {
    if (this.parentId) {
      this.router.navigate(['/records', this.parentId]);
    } else {
      this.router.navigate(['/records', 'model', this.model._id]);
    }
  }

  navigateToModel(id: string) {
    this.router.navigate(['/records', id], {
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    });
  }

  private applyModelToData(model: Model) {
    this.model = model;

    // Set initial data values
    model.columns.forEach((column) => {
      if (
        this.data.data[column.name] === undefined ||
        this.data.data[column.name] === null
      ) {
        if (column.type === 'boolean') {
          this.data.data[column.name] = !!column.boolean_default;
        } else if (column.type === 'double' || column.type === 'integer') {
          this.data.data[column.name] = 0;
        } else if (column.type === 'file') {
          this.data.data[column.name] = [];
        } else {
          this.data.data[column.name] = '';
        }
      }
    });

    // Get choices for model
    const choicesColumns: Column[] = [];
    model.columns.forEach((column) => {
      if (column.type === 'options' && !this.options[column.options]) {
        choicesColumns.push(column);
      }
    });

    // Get choices for child models
    model.children.forEach((childModel) => {
      childModel.columns.forEach((column) => {
        if (
          column.type === 'options' &&
          !this.options[column.options] &&
          !choicesColumns.some((cc) => cc.options == column.options)
        ) {
          choicesColumns.push(column);
        }
      });
    });

    // Load choices for options
    choicesColumns.forEach((column) => {
      this.options[column.options] = [];
      this.api
        .getRecordsData(column.options, column.options_column)
        .subscribe((r) => (this.options[column.options] = r));
    });

    // Get area
    if (
      this.model.has_geometry &&
      this.model.geometry_type == 'Polygon' &&
      this.data.geometry
    ) {
      if (this.data.geometry.type == 'MultiPolygon') {
        this.area = this.getArea(
          new MultiPolygon(this.data.geometry.coordinates).transform(
            'EPSG:4326',
            'EPSG:3857'
          )
        );
      } else {
        this.area = this.getArea(
          new Polygon(this.data.geometry.coordinates).transform(
            'EPSG:4326',
            'EPSG:3857'
          )
        );
      }
    }

    // Get prev and next
    if (this.model.parent_id) {
      this.api.readRecord(this.data.parent_id).subscribe((parentRecord) => {
        const i = parentRecord.children.findIndex(
          (ch) => ch._id == this.data._id
        );

        if (i > 0) {
          this.prev = {
            routerLink: ['/records', parentRecord.children[i - 1]._id],
            queryParams: { parentId: parentRecord._id },
          };
        }

        if (i + 1 < parentRecord.children.length) {
          this.next = {
            routerLink: ['/records', parentRecord.children[i + 1]._id],
            queryParams: { parentId: parentRecord._id },
          };
        }
      });
    }

    setTimeout(() => this.ngAfterViewInit(), 0);
  }

  ngAfterViewInit() {
    if (!this.data || !this.model || !this.model.has_geometry) {
      return;
    }

    const rasters = [];

    for (let imagerySet of this.imagerySets) {
      const layer = new TileLayer({
        visible: imagerySet.value == this.imagerySet,
        preload: Infinity,
        source: new BingMaps({
          key: environment.bingKey,
          imagerySet: imagerySet.value,
        }),
      });

      layer.imagerySet = imagerySet.value;
      rasters.push(layer);
    }

    this.view = new View({
      center: [0, 0],
      zoom: 18,
    });

    this.geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    const accuracyFeature = new Feature();

    this.geolocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });

    this.geolocation.on('change:position', () => {
      this.locateMe();
    });

    this.geolocation.on('error', (e) => {
      console.error(e);
    });

    this.positionFeature = new Feature();

    this.positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    const vector = new VectorLayer({
      source: new VectorSource({
        features: [accuracyFeature, this.positionFeature],
      }),
    });

    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features: [
        {
          type: 'Feature',
          geometry: this.data.geometry,
        },
      ],
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.obj.setSource(geojson);

    this.drawSource = new VectorSource();

    this.map = new Map({
      target: this.mapEl.nativeElement,
      layers: [...rasters, vector, this.obj, this.boundary],
      view: this.view,
      interactions: defaultInteractions({
        onFocusOnly: true,
      }),
    });

    const modify = new Modify({ source: this.drawSource });
    this.map.addInteraction(modify);

    if (this.data.geometry) {
      this.view.fit(geojson.getExtent());
    }

    this.view.setZoom(18);
  }

  drawObject() {
    this.drawSource.clear();
    this.drawing = true;

    this.draw = new Draw({
      source: this.drawSource,
      type: this.model.geometry_type,
    });

    this.obj.setSource(this.drawSource);

    this.draw.on('drawend', () => {
      setTimeout(() => {
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);

        const coordinates = this.drawSource
          .getFeatures()[0]
          .getGeometry()
          .transform('EPSG:3857', 'EPSG:4326')
          .getCoordinates();

        this.data.geometry = {
          type: this.model.geometry_type,
          coordinates,
        };

        this.drawing = false;

        this.drawSource
          .getFeatures()[0]
          .getGeometry()
          .transform('EPSG:4326', 'EPSG:3857');

        if (this.model.has_geometry && this.model.geometry_type == 'Polygon') {
          this.area = this.getArea(
            this.drawSource.getFeatures()[0].getGeometry()
          );
        }
      }, 0);
    });

    this.map.addInteraction(this.draw);
    this.snap = new Snap({ source: this.drawSource });
    this.map.addInteraction(this.snap);
  }

  locateMe() {
    const coordinates = this.geolocation.getPosition();
    this.positionFeature.setGeometry(
      coordinates ? new Point(coordinates) : null
    );

    if (coordinates && !this.centered && !this.data.geometry) {
      this.view.setCenter(coordinates);
      this.centered = true;
    }
  }

  changeImagerySet() {
    for (let layer of this.map.getLayers().array_) {
      if (layer.imagerySet) {
        layer.setVisible(layer.imagerySet == this.imagerySet);
      }
    }
  }

  onFileChange(e: any, columnName: string) {
    for (let file of e.target.files) {
      this.api
        .uploadFile(file)
        .subscribe((r) => this.data.data[columnName].push(r.url));
    }
  }

  getArea(polygon: Polygon) {
    const area = getArea(polygon);
    let output;

    if (area > 10000) {
      output =
        Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }

    return output;
  }

  displayChildColumn(column: Column, childRecord: Record): string {
    const value = childRecord.data[column.name];

    if (column.type != 'options' || !this.options[column.options]) {
      return value;
    }

    const option = this.options[column.options].find(
      (option) => option._id == value
    );

    if (option && option[column.options_column]) {
      return option[column.options_column];
    } else {
      return value;
    }
  }

  setBoundary() {
    const features = [];
    const project = this.projects.find(
      (p) => p._id == this.auth.getUserProjectId()
    );

    if (project) {
      features.push(project.geometry);
    }

    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features,
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.boundary.setSource(geojson);
  }

  fillInitialValues() {
    for (let column in this.data.data) {
      if (
        !this.data.data[column] &&
        this.initialData &&
        this.initialData.data &&
        this.initialData.data[column] !== undefined
      ) {
        this.data.data[column] = this.initialData.data[column];
      }
    }

    this.notifier.notify(
      'default',
      'Prazna polja su popunjena incijalnim vrijednostima'
    );
  }

  ngOnDestroy() {
    if (this.geolocation) {
      this.geolocation.setTracking(false);
    }
  }
}
