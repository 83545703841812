import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { TokenResponse } from '../models/token-response';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private api: ApiService
  ) {
    // Retrieve current user if a token is available
    if (this.hasValidToken()) {
      this.retrieveUser();
    }

    // Set a 1-minute interval to refresh the token
    setInterval(() => this.tryRefresh(), 60000);

    // Try refreshing token on init
    this.tryRefresh();
  }

  hasValidToken(): boolean {
    const token = localStorage.getItem('token');
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  retrieveUser() {
    if (this.hasValidToken()) {
      this.api.me().subscribe(
        (user: User) => {
          localStorage.setItem('user', JSON.stringify(user));
        },
        (e: HttpErrorResponse) => {
          this.logOut();
        }
      );
    }
  }

  getUserId(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user._id : null;
  }

  getUserName(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.name : null;
  }

  getUserProjectId(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.project_id : null;
  }

  isRole(role: User['role']): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.role === role;
  }

  isAnyRole(roles: User['role'][]): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && roles.some(role => role === user.role);
  }

  tryRefresh() {
    if (this.hasValidToken()) {
      const token = localStorage.getItem('token');
      const tokenExp = this.jwtHelper.getTokenExpirationDate(token);
      const now = moment.now();

      if (moment(tokenExp).diff(now) < 900000) { // 15 minutes
        this.api.refresh().subscribe(
          (tokenResponse: TokenResponse) => {
            this.setToken(tokenResponse.access_token);
          },
          (e: HttpErrorResponse) => {
            this.logOut();
          }
        );
      }
    }
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  logOut() {
    if (this.hasValidToken()) {
      this.api.logout().subscribe();
    }

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    this.router.navigate(['/login']);
  }

}
