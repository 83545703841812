import { ApiService } from 'src/app/services/api.service';
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { AuthService } from 'src/app/services/auth.service';
import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Map, View, Geolocation, Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { OSM, BingMaps, Vector as VectorSource, XYZ } from 'ol/source';
import { Point } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Project } from 'src/app/models/project';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
  map: Map;
  geolocation: any;
  positionFeature: any;
  view: any;
  imagerySet: string = 'AerialWithLabelsOnDemand';
  imagerySets = [
    { name: 'Bing Ortofoto', value: 'AerialWithLabelsOnDemand' },
    { name: 'Bing Karta', value: 'RoadOnDemand' },
  ];
  projects: Project[] = [];
  project: Project;
  openRec: any;
  reviewRec: any;
  closedRec: any;
  boundary: any = new VectorLayer({
    style: new Style({
      fill: new Fill({
        color: 'transparent',
      }),
      stroke: new Stroke({
        color: 'red',
        width: 3,
      }),
    }),
  });

  openRecStyle: any = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: 'red' }),
      stroke: new Stroke({
        color: [255, 255, 255, 0.75],
        width: 2,
      }),
    }),
    fill: new Fill({
      color: 'rgba(255, 0, 0, 0.5)',
    }),
    stroke: new Stroke({
      color: 'red',
      width: 1,
    }),
  });

  reviewRecStyle: any = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: 'blue' }),
      stroke: new Stroke({
        color: [255, 255, 255, 0.75],
        width: 2,
      }),
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.5)',
    }),
    stroke: new Stroke({
      color: 'blue',
      width: 1,
    }),
  });

  closedRecStyle: any = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: 'green' }),
      stroke: new Stroke({
        color: [255, 255, 255, 0.75],
        width: 2,
      }),
    }),
    fill: new Fill({
      color: 'rgba(0, 255, 0, 0.5)',
    }),
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  });

  private centered: boolean = false;

  @ViewChild('map') mapEl: ElementRef;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public api: ApiService,
    public auth: AuthService,
    public store: StoreService
  ) {
    this.api.allProjects('name', 'asc').subscribe((r) => {
      if (this.auth.isRole('operator')) {
        this.projects = r.filter(
          (rp) => rp._id == this.auth.getUserProjectId()
        );
      } else {
        this.projects = r;
      }

      if (this.projects[0]) {
        this.project = this.projects[0];
        this.changeProject();
      }
    });
  }

  ngAfterViewInit() {
    const rasters = [];

    for (let imagerySet of this.imagerySets) {
      const layer = new TileLayer({
        visible: imagerySet.value == this.imagerySet,
        preload: Infinity,
        source: new BingMaps({
          key: environment.bingKey,
          imagerySet: imagerySet.value,
        }),
      });

      layer.imagerySet = imagerySet.value;
      rasters.push(layer);
    }

    // const gsat = new TileLayer({
    //   visible: false,
    //   source: new XYZ({
    //     url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
    //   }),
    // });

    // gsat.imagerySet = 'google-sat';
    // rasters.push(gsat);

    this.view = new View({
      center: [0, 0],
      zoom: 18,
    });

    this.geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    const accuracyFeature = new Feature();

    this.geolocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });

    this.geolocation.on('change:position', () => {
      this.locateMe();
    });

    this.positionFeature = new Feature();

    this.positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    const vector = new VectorLayer({
      source: new VectorSource({
        features: [accuracyFeature, this.positionFeature],
      }),
    });

    this.openRec = new VectorLayer({
      source: new VectorSource(),
      style: this.openRecStyle,
    });

    this.reviewRec = new VectorLayer({
      source: new VectorSource(),
      style: this.reviewRecStyle,
    });

    this.closedRec = new VectorLayer({
      source: new VectorSource(),
      style: this.closedRecStyle,
    });

    this.map = new Map({
      target: this.mapEl.nativeElement,
      layers: [
        ...rasters,
        vector,
        this.openRec,
        this.reviewRec,
        this.closedRec,
        this.boundary,
      ],
      view: this.view,
    });

    this.map.on('click', this.onClick.bind(this));
  }

  changeImagerySet() {
    for (let layer of this.map.getLayers().array_) {
      if (layer.imagerySet) {
        layer.setVisible(layer.imagerySet == this.imagerySet);
      }
    }
  }

  changeProject() {
    this.api.getRecordsGeom(this.project._id).subscribe((r) => {
      const openRecGeojson = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326',
          },
        },
        features: r
          .filter((f) => f.status === 'open')
          .filter((f) => f.geometry)
          .map((f) => {
            return {
              type: 'Feature',
              properties: { _id: f._id },
              geometry: f.geometry,
            };
          }),
      };

      const reviewRecGeojson = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326',
          },
        },
        features: r
          .filter((f) => f.status === 'review')
          .filter((f) => f.geometry)
          .map((f) => {
            return {
              type: 'Feature',
              properties: { _id: f._id },
              geometry: f.geometry,
            };
          }),
      };

      const closedRecGeojson = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326',
          },
        },
        features: r
          .filter((f) => f.status === 'closed')
          .filter((f) => f.geometry)
          .map((f) => {
            return {
              type: 'Feature',
              properties: { _id: f._id },
              geometry: f.geometry,
            };
          }),
      };

      const openRecVector = new VectorSource({
        features: new GeoJSON().readFeatures(openRecGeojson, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });

      const reviewRecVector = new VectorSource({
        features: new GeoJSON().readFeatures(reviewRecGeojson, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });

      const closedRecVector = new VectorSource({
        features: new GeoJSON().readFeatures(closedRecGeojson, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });

      this.openRec.setSource(openRecVector);
      this.reviewRec.setSource(reviewRecVector);
      this.closedRec.setSource(closedRecVector);
      this.setBoundary();
    });
  }

  setBoundary() {
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features: this.project?.geometry ? [this.project.geometry] : [],
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.boundary.setSource(geojson);
  }

  zoomToProject(e: any) {
    e.stopPropagation();

    if (this.boundary.getSource()) {
      this.view.fit(this.boundary.getSource().getExtent());
    }
  }

  locateMe() {
    const coordinates = this.geolocation.getPosition();
    this.positionFeature.setGeometry(
      coordinates ? new Point(coordinates) : null
    );

    if (coordinates && !this.centered) {
      this.view.setCenter(coordinates);
      this.centered = true;
    }
  }

  onClick(e: any) {
    this.openRec.getFeatures(e.pixel).then((f) => {
      if (f.length) {
        this.router.navigate(['/records', f[0].values_._id]);
      }
    });

    this.reviewRec.getFeatures(e.pixel).then((f) => {
      if (f.length && this.auth.isAnyRole(['admin', 'moderator'])) {
        this.router.navigate(['/records', f[0].values_._id]);
      }
    });

    this.closedRec.getFeatures(e.pixel).then((f) => {
      if (f.length && this.auth.isRole('admin')) {
        this.router.navigate(['/records', f[0].values_._id]);
      }
    });
  }

  ngOnDestroy() {
    if (this.geolocation) {
      this.geolocation.setTracking(false);
    }

    document
      .querySelectorAll('canvas')
      .forEach((el) => el.parentElement.removeChild(el));
  }
}
