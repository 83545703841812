<div class="title">
  <a
    mat-icon-button
    routerLink=".."
    color="primary"
    aria-label="Prikaži sve projekte"
  >
    <mat-icon>list</mat-icon>
  </a>

  <h1 *ngIf="isEdit && data">Projekt: {{ data.name }}</h1>
  <h1 *ngIf="!isEdit">Novi projekt</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">
  <!-- name -->
  <mat-form-field>
    <mat-label>Naziv projekta</mat-label>
    <input
      matInput
      [(ngModel)]="data.name"
      type="text"
      name="name"
      required
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-error *ngIf="errors.name">{{ errors.name[0] }}</mat-error>
  </mat-form-field>

  <!-- project_group_id -->
  <mat-form-field>
    <mat-label>Grupa</mat-label>
    <mat-select
      [(ngModel)]="data.project_group_id"
      name="project_group_id"
      required
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option
        *ngFor="let projectGroup of projectGroups"
        [value]="projectGroup._id"
        >{{ projectGroup.name }}</mat-option
      >
    </mat-select>
    <mat-error *ngIf="errors.project_group_id">{{
      errors.project_group_id[0]
    }}</mat-error>
  </mat-form-field>

  <!-- moderator_id -->
  <mat-form-field *ngIf="auth.isRole('admin')">
    <mat-label>Moderator</mat-label>
    <mat-select
      [(ngModel)]="data.moderator_id"
      name="moderator_id"
      required
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option
        *ngFor="let moderator of moderators"
        [value]="moderator._id"
        >{{ moderator.name }}</mat-option
      >
    </mat-select>
    <mat-hint>Vlasnik projekta</mat-hint>
    <mat-error *ngIf="errors.moderator_id">{{
      errors.moderator_id[0]
    }}</mat-error>
  </mat-form-field>

  <!-- operator_ids -->
  <mat-form-field>
    <mat-label>Operatori</mat-label>
    <mat-select
      [(ngModel)]="data.operator_ids"
      name="operator_ids"
      multiple
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option *ngFor="let operator of operators" [value]="operator._id">{{
        operator.name
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.operator_ids">{{
      errors.operator_ids[0]
    }}</mat-error>
  </mat-form-field>

  <!-- map -->
  <div *ngIf="isEdit" class="map">
    <div #map class="map__ol" tabindex="0">
      <mat-form-field class="mat-elevation-z2">
        <mat-label>Podloga</mat-label>
        <mat-select
          [(ngModel)]="imagerySet"
          (ngModelChange)="changeImagerySet()"
          name="imagerySet"
          required
        >
          <mat-option
            *ngFor="let imagerySet of imagerySets"
            [value]="imagerySet.value"
            >{{ imagerySet.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="map__buttons">
      <mat-form-field>
        <mat-label>Model</mat-label>
        <mat-select
          [(ngModel)]="modelId"
          (ngModelChange)="this.data?.geometry && getIntersection()"
          name="modelId"
        >
          <ng-container *ngFor="let model of store.models">
            <mat-option
              *ngIf="model.has_geometry && !model.parent_id"
              [value]="model._id"
              >{{ model.name }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      <button
        mat-raised-button
        (click)="drawBoundaries()"
        [disabled]="drawing || api.isLoading"
        color="primary"
        type="button"
      >
        Nacrtaj obuhvatni poligon
      </button>
      <button
        *ngIf="selectedObjects.length"
        mat-raised-button
        (click)="assignRecordsToProject()"
        [disabled]="drawing || api.isLoading"
        color="primary"
        type="button"
      >
        Pridruži objekte projektu ({{ selectedObjects.length }})
      </button>
    </div>
  </div>

  <!-- records -->
  <!-- <mat-label class="accordion-label">Zapisi</mat-label>
  <mat-selection-list *ngIf="isEdit && data.records.length" [multiple]="false">
    <mat-list-option *ngFor="let record of data.records" [value]="record" [routerLink]="['/records', record._id]">
      <span class="id">{{ record._id }}</span>
      <ng-container *ngFor="let column of record.columns">
        <span *ngIf="column.in_listing">{{ column.name }}: {{ record.data[column.name] }}</span>
      </ng-container>
    </mat-list-option>
  </mat-selection-list> -->

  <div class="buttons buttons--component">
    <button mat-raised-button [disabled]="api.isLoading" color="primary">
      {{ isEdit ? "Snimi" : "Kreiraj" }}
    </button>
    <button
      *ngIf="isEdit"
      mat-raised-button
      (click)="delete()"
      [disabled]="api.isLoading"
      color="warn"
      type="button"
    >
      Obriši
    </button>
  </div>
</form>
