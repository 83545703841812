import { ViewChild, Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { Paginated } from 'src/app/models/paginated';

@Component({
  template: '',
  animations: [
    trigger('optionsExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class IndexComponent {

  public data: any[] = [];
  public displayedColumns: string[] = [];
  public expandedRow: any;
  public limit: number[] = [5, 10, 25, 50, 100];
  public resultsLength: number = 0;
  public isLoadingResults: boolean = true;

  protected apiSlug: string = '';
  protected deleteConfirmationMessage: string = 'Are you sure you want to delete this entry?';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(public api: ApiService) { }

  index() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.indexApi();
      }),
      map(paginated => {
        this.isLoadingResults = false;
        this.resultsLength = paginated.total;
        return paginated.data;
      })
    ).subscribe(data => this.data = data);
  }

  indexApi(): Observable<Paginated> {
    const method = this.getMethodName('index', true);
    return this.api[method](this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction) as Observable<Paginated>;
  }

  deleteRow(id: string) {
    if (confirm(this.deleteConfirmationMessage)) {
      const method = this.getMethodName('delete');
      this.api[method](id).subscribe(() => this.index());
    }
  }

  private getMethodName(operation: string, plural: boolean = false) {
    return operation + this.apiSlug + (plural ? 's' : '');
  }

  ngAfterViewInit() {
    this.index();
  }

}
