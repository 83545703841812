<div class="title">
  <h1>Eksport zapisa</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">
  <!-- Model -->
  <mat-form-field>
    <mat-label>Model</mat-label>
    <mat-select
      [(ngModel)]="selectedModel"
      (ngModelChange)="onModelChange()"
      name="selectedModel"
      required
    >
      <mat-option *ngFor="let model of store.models" [value]="model">{{
        model.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Columns -->
  <mat-label *ngIf="selectedModel" class="accordion-label">Kolone</mat-label>
  <mat-accordion *ngIf="selectedModel" multi>
    <mat-expansion-panel *ngFor="let column of columns; let i = index">
      <mat-expansion-panel-header>
        <div class="expansion-panel-header">
          <mat-panel-title>{{ column.name }}</mat-panel-title>
        </div>
      </mat-expansion-panel-header>
      <div class="expansion-panel-fields">
        <!-- column -->
        <mat-form-field>
          <mat-label>Naziv kolone</mat-label>
          <input
            matInput
            [(ngModel)]="column.name"
            type="text"
            [name]="'columns.' + i + '.name'"
            required
          />
        </mat-form-field>
      </div>
      <mat-action-row>
        <button
          mat-icon-button
          *ngIf="i > 0"
          (click)="moveColumn(i, i - 1)"
          type="button"
          aria-label="Pomjeri gore"
        >
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="i < columns.length - 1"
          (click)="moveColumn(i, i + 1)"
          type="button"
          aria-label="Pomjeri dole"
        >
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <button
          mat-button
          (click)="columns.splice(i, 1)"
          color="warn"
          type="button"
        >
          Obriši kolonu
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <div *ngIf="selectedModel" class="buttons">
    <button
      mat-stroked-button
      (click)="columns.push({ name: '' })"
      color="primary"
      type="button"
    >
      Dodaj kolonu
    </button>
  </div>

  <!-- Buttons -->
  <div class="buttons buttons--component">
    <button
      mat-raised-button
      [disabled]="api.isLoading || !selectedModel"
      color="primary"
    >
      {{ api.isLoading ? "Molimo sačekajte..." : "Eksportuj" }}
    </button>
  </div>
</form>
