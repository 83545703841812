import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {

	constructor(
		private auth: AuthService,
		private router: Router
	) { }

	canActivate(): boolean {
		if (this.auth.hasValidToken()) {
			this.router.navigate(['/']);
			return false;
		}

		return true;
	}

}
