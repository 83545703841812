import { ApiService } from 'src/app/services/api.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

export class EditComponent implements OnInit {

  id: string = this.route.snapshot.paramMap.get('id');
  isEdit: boolean = this.id && this.id !== 'new';
  data: any = {};
  errors: any = {};
  errorStateMatcher = new ErrorStateMatcher();

  protected apiSlug: string = '';
  protected fillerData: any = {};
  protected deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovaj model?';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.errorStateMatcher.isErrorState = (control: FormControl, form: NgForm): boolean => {
      const name = Object.keys(form.controls).find(name => form.controls[name] === control);
      return !name || this.errors[name];
    };
  }

  fill(data: any = null) {
    if (data) {
      this.fillerData = data;
    }

    for (let name in this.fillerData) {
      if (this.data[name] === undefined) {
        this.data[name] = this.fillerData[name];
      }
    }
  }

  loaded() { }

  navigateToList() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  navigateToModel(id: string) {
    this.router.navigate(['..', id], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
  }

  delete() {
    if (confirm(this.deleteConfirmationMessage)) {
      const method = this.getMethodName('delete');

      this.api[method](this.id).subscribe(
        (r: any) => {
          this.navigateToList();
        }
      )
    }
  }

  onSubmit(e: any) {
    e && e.preventDefault();

    this.errors = {};

    if (this.isEdit) {
      const method = this.getMethodName('update');

      this.api[method](this.data).subscribe(
        (r: any) => {
          this.data = r.data;
          this.fill();
          this.loaded();
        },
        (e: HttpErrorResponse) => this.errors = e.error.errors
      );
    } else {
      const method = this.getMethodName('create');

      this.api[method](this.data).subscribe(
        (r: any) => {
          this.navigateToModel(r.data._id);
        },
        (e: HttpErrorResponse) => this.errors = e.error.errors
      );
    }
  }

  private getMethodName(operation: string) {
    return operation + this.apiSlug;
  }

  ngOnInit() {
    if (this.isEdit) {
      const method = this.getMethodName('read');

      this.api[method](this.id).subscribe(data => {
        this.data = data;
        this.fill();
        this.loaded();
      });
    }
  }

}
