import { AuthService } from 'src/app/services/auth.service';
import { Component, Output, EventEmitter } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  environment = environment;

  @Output() drawerClose = new EventEmitter();

  constructor(public auth: AuthService, public store: StoreService) {}
}
