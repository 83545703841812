<div class="title">
  <h1>Import zapisa</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">
  <mat-form-field>
    <mat-label>Model</mat-label>
    <mat-select [(ngModel)]="modelId" name="modelId" required [errorStateMatcher]="errorStateMatcher">
      <mat-option *ngFor="let model of store.models" [value]="model._id">{{ model.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.modelId">{{ errors.modelId[0] }}</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="modelId && !isParentModel(modelId)">
    <mat-label>Veza na model {{ store.getModel(store.getModel(modelId).parent_id).name }}</mat-label>
    <mat-select [(ngModel)]="fk" name="fk" required [errorStateMatcher]="errorStateMatcher">
      <mat-option *ngFor="let column of store.getModel(modelId).columns" [value]="column.name">{{ column.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.fk">{{ errors.fk[0] }}</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="modelId && !isParentModel(modelId)">
    <mat-label>Primarni ključ modela {{ store.getModel(store.getModel(modelId).parent_id).name }}</mat-label>
    <mat-select [(ngModel)]="ok" name="ok" required [errorStateMatcher]="errorStateMatcher">
      <mat-option *ngFor="let column of store.getModel(store.getModel(modelId).parent_id).columns" [value]="column.name">{{ column.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.ok">{{ errors.ok[0] }}</mat-error>
  </mat-form-field>
  <mat-form-field (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <mat-label *ngIf="!modelId">(Geo)JSON</mat-label>
    <mat-label *ngIf="modelId">{{ store.getModel(modelId).has_geometry ? 'GeoJSON' : 'JSON' }}</mat-label>
    <textarea matInput [(ngModel)]="geojson" name="geojson" rows="10" data-gramm_editor="false" required [errorStateMatcher]="errorStateMatcher"></textarea>
    <mat-hint>Prevucite fajl u polje iznad</mat-hint>
    <mat-error *ngIf="errors.geojson || modelError">{{ errors.geojson ? errors.geojson[0] : modelError }}</mat-error>
  </mat-form-field>
  <div class="buttons buttons--component">
    <button mat-raised-button [disabled]="api.isLoading" color="primary">{{ api.isLoading ? 'Molimo sačekajte...' : 'Importuj' }}</button>
  </div>
</form>
