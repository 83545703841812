import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  template: ''
})
export class LogoutComponent {

  constructor(private auth: AuthService) {
    this.auth.logOut();
  }

}
