export const environment = {
  production: true,
  adm: 'rgu',
  title: 'Registar objekata RGU',
  jwt: {
   // whitelistedDomains: ['api.rgurs.org'],
    whitelistedDomains: ['localhost:8000','127.0.0.1:8000','api.rgurs.org','localhost:4200','192.168.0.18:3232','192.168.111.41:3232']
  },
  api: {
  url: 'http://api.rgurs.org',
  // url: 'http://127.0.0.1:8000',
   //url: 'http://192.168.0.18:3232',
  //url: 'http://192.168.111.41:3232'
  },
 // bingKey: 'bodpXfLh0q9GvIYyZcUj~OEaCSBrPWdJYSzTtbT5k3w~AhbV1nQc3NR4XDz6vYDM2RQHSCrUuGAdYlyS2zicDM5kbROzaYqSUkuqXHuswxuP',
 bingKey: 'AmL-MxvEH5xOiqQKu5jjZIS3pnBZ7GzyS4V0F91ukVJ7iL-EEbLPE9e_VXHtwtTZ',
};
