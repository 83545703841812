import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, AfterViewInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IndexComponent } from '../index/index.component';
import { Model } from 'src/app/models/model';
import { Observable } from 'rxjs';
import { Paginated } from 'src/app/models/paginated';
import { Project } from 'src/app/models/project';
import { Record } from 'src/app/models/record';
import { Router, ActivatedRoute } from '@angular/router';
import { StringHelper } from 'src/app/helpers/string.helper';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
})
export class RecordsComponent extends IndexComponent implements AfterViewInit {
  modelId: string = this.route.snapshot.paramMap.get('modelId');
  model: Model;
  projects: Project[] = [];
  projectId: string | 'without';
  status: Record['status'];
  options: any = {};
  dataFilters: any = {};

  protected apiSlug = 'Record';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public api: ApiService,
    public auth: AuthService,
    public str: StringHelper
  ) {
    super(api);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.deleteConfirmationMessage =
      'Da li ste sigurni da želite obrisati ovaj zapis i sve njegove veze?';

    if (this.auth.isAnyRole(['admin', 'moderator'])) {
      this.api
        .allProjects('name', 'asc')
        .subscribe((response) => (this.projects = response));
    }
  }

  getStatusName(status: Record['status']): string {
    switch (status) {
      case 'open':
        return 'Otvoren';
      case 'review':
        return 'U pregledu';
      case 'closed':
        return 'Zaključen';
      default:
        return '-';
    }
  }

  indexApi(): Observable<Paginated> {
    const opt: any = {};

    if (this.projectId) {
      opt.project_id = this.projectId;
    }

    if (this.status) {
      opt.status = this.status;
    }

    for (let columnName in this.dataFilters) {
      if (this.dataFilters[columnName]) {
        opt['data_' + columnName] = this.dataFilters[columnName];
      }
    }

    return this.api.indexRecords(
      this.modelId,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      opt
    ) as Observable<Paginated>;
  }

  moderatorHasPermissions(projectId: string): boolean {
    return this.projects.some(
      (p) => p._id === projectId && p.moderator_id === this.auth.getUserId()
    );
  }

  ngAfterViewInit() {
    this.api.readModel(this.modelId).subscribe(
      (model) => {
        this.model = model;
        this.displayedColumns = model.columns
          .filter((c) => c.in_listing)
          .map((c) => c.name)
          .concat(['project', 'status', 'created_at', 'updated_at']);

        if (model.is_options_model) {
          this.displayedColumns = this.displayedColumns.filter(
            (c) => c !== 'project'
          );
        }

        model.columns
          .filter((c) => c.in_listing && c.options)
          .forEach((c) => {
            this.api
              .getRecordsData(c.options, c.options_column)
              .subscribe((r) => {
                this.options[c.name] = {};
                this.dataFilters[c.name] = null;
                r.forEach(
                  (e) => (this.options[c.name][e._id] = e[c.options_column])
                );
              });
          });

        setTimeout(() => this.index(), 0);
      },
      (e: HttpErrorResponse) => {
        if (e.status === 403) {
          this.router.navigate(['/']);
        }
      }
    );
  }
}
