<div #map class="map" tabindex="0">
  <div class="menu mat-elevation-z2">
    <mat-form-field>
      <mat-label>Podloga</mat-label>
      <mat-select
        [(ngModel)]="imagerySet"
        (ngModelChange)="changeImagerySet()"
        name="imagerySet"
        required
      >
        <mat-option
          *ngFor="let imagerySet of imagerySets"
          [value]="imagerySet.value"
          >{{ imagerySet.name }}</mat-option
        >
        <!-- <mat-option value="google-sat">Google Sat</mat-option> -->
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Projekt</mat-label>
      <mat-select
        [(ngModel)]="project"
        (ngModelChange)="changeProject()"
        name="project"
        required
      >
        <mat-option *ngFor="let project of projects" [value]="project">{{
          project.name
        }}</mat-option>
      </mat-select>
      <button
        *ngIf="this.boundary.getSource()"
        mat-icon-button
        matSuffix
        (click)="zoomToProject($event)"
      >
        <mat-icon>{{ "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
