import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private auth: AuthService) { }

	canActivate(): boolean {
		if (!this.auth.hasValidToken()) {
			this.auth.logOut();
			return false;
		}

		return true;
	}

}
