<div class="title">
  <a mat-icon-button routerLink=".." color="primary" aria-label="Prikaži sve grupe projekata">
    <mat-icon>list</mat-icon>
  </a>

  <h1 *ngIf="isEdit && data">Grupa projekata: {{ data.name }}</h1>
  <h1 *ngIf="!isEdit">Nova grupa projekata</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">

  <!-- name -->
  <mat-form-field>
    <mat-label>Naziv grupe</mat-label>
    <input matInput [(ngModel)]="data.name" type="text" name="name" [errorStateMatcher]="errorStateMatcher">
    <mat-error *ngIf="errors.name">{{ errors.name[0] }}</mat-error>
  </mat-form-field>

  <div class="buttons buttons--component">
    <button mat-raised-button [disabled]="api.isLoading" color="primary">{{ isEdit ? 'Snimi' : 'Kreiraj' }}</button>
    <button *ngIf="isEdit" mat-raised-button (click)="delete()" [disabled]="api.isLoading" color="warn" type="button">Obriši</button>
  </div>
</form>
