import { ApiService } from '../services/api.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { tap, finalize } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private jobs: string[] = [];

  constructor(
    private router: Router,
    private api: ApiService,
    private notifier: NotifierService,
    private location: Location
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addJob(req.url);

    // const timeout = setTimeout(() => this.removeJob(req.url), 10000);

    return next.handle(req).pipe(
      tap(
        (r: HttpResponse<any>) => {
          if (r.body !== undefined) {
            this.api.response.next({
              path: new URL(req.url).pathname,
              method: req.method,
              response: r.body
            });
          }

          if (r.body && r.body.message) {
            this.notifier.notify('default', r.body.message);
          }

          return r;
        },
        (e: HttpErrorResponse) => {
          if (e.status === 401) {
            localStorage.removeItem('token');
            this.router.navigate(['/logout']);
          } else if (e.status === 403) {
            this.notifier.notify('error', 'Zabranjen pristup');
          } else if (e.status === 404) {
            this.notifier.notify('error', 'Traženi resurs ne postoji');
            this.location.back();
          } else if (e.status === 422) {
            this.notifier.notify('error', 'Neki od unesenih podataka nisu validni');
          } else if (e.error && e.error.message) {
            this.notifier.notify('error', e.error.message);
          }

          return e;
        }
      ),
      finalize(
        () => {
          this.removeJob(req.url);
          // clearTimeout(timeout);
        }
      )
    );
  }

  private addJob(url: string) {
    this.jobs.push(url);
    this.updateState();
  }

  private removeJob(url: string) {
    const i = this.jobs.indexOf(url);

    if (i > -1) {
      this.jobs.splice(i, 1);
    }

    this.updateState();
  }

  private updateState() {
    setTimeout(() => this.api.isLoading = !!this.jobs.length, 0);
  }

}
