import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { StringHelper } from 'src/app/helpers/string.helper';
import { TokenResponse } from 'src/app/models/token-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public email: string = '';
  public password: string = '';
  public error: string;

  constructor(
    private router: Router,
    public api: ApiService,
    public auth: AuthService,
    public str: StringHelper
  ) { }

  isValid() {
    return this.email && this.str.isEmail(this.email) && this.password;
  }

  onSubmit(e: any) {
    e && e.preventDefault();
    this.api.login(this.email, this.password).subscribe(
      (tokenResponse: TokenResponse) => {
        this.auth.setToken(tokenResponse.access_token);
        this.auth.retrieveUser();
        this.router.navigate(['/']);
      },
      (e: HttpErrorResponse) => {
        if (e.status === 401) {
          this.error = 'Pristupni podaci nisu ispravni.';
        }

      }
    );
  }

}
