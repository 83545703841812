import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { NotifierService } from 'angular-notifier';
import { Project } from 'src/app/models/project';
import { ProjectGroup } from 'src/app/models/project-group';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { User } from 'src/app/models/user';

import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import {
  defaults as defaultInteractions,
  Draw,
  Modify,
  Snap,
} from 'ol/interaction';
import { GeoJSON } from 'ol/format';
import { Map, View, Geolocation, Feature } from 'ol';
import { OSM, BingMaps, Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent extends EditComponent implements AfterViewInit {
  data: Project;
  projectGroups: ProjectGroup[] = [];
  moderators: User[] = [];
  operators: User[] = [];
  modelId: string;
  map: Map;
  draw: any;
  drawSource: any;
  drawing: boolean = false;
  snap: any;
  obj: any;
  boundary: any = new VectorLayer({
    style: new Style({
      fill: new Fill({
        color: 'transparent',
      }),
      stroke: new Stroke({
        color: 'red',
        width: 3,
      }),
    }),
  });
  otherBoundaries: any = new VectorLayer({
    style: this.getOtherBoundariesStyle.bind(this),
  });
  centered: boolean = false;
  geolocation: any;
  positionFeature: any;
  view: any;
  imagerySet: string = 'AerialWithLabelsOnDemand';
  imagerySets = [
    { name: 'Bing Ortofoto', value: 'AerialWithLabelsOnDemand' },
    { name: 'Bing Karta', value: 'RoadOnDemand' },
  ];
  selectedObjects: string[] = [];

  protected apiSlug = 'Project';

  @ViewChild('map') mapEl: ElementRef;

  constructor(
    private notifier: NotifierService,
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
    public auth: AuthService,
    public store: StoreService
  ) {
    super(route, router, api);

    const blank: Project = {
      name: '',
      project_group_id: null,
      moderator_id: this.auth.isRole('moderator') ? this.auth.getUserId() : '',
      operator_ids: [],
      operators: [],
      records: [],
      geometry: null,
    };

    this.fill(blank);

    this.deleteConfirmationMessage =
      'Da li ste sigurni da želite obrisati ovaj projekt?';

    this.api.allUsers('name', 'asc').subscribe((response) => {
      this.moderators = response.filter((user) => user.role === 'moderator');
      this.operators = response.filter(
        (user) =>
          user.role === 'operator' &&
          (user.project_id === this.id || !user.project_id)
      );
      this.loaded();
    });

    this.api.allProjectGroups('name', 'asc').subscribe((response) => {
      this.projectGroups = response;
      this.loaded();
    });
  }

  loaded() {
    this.data.operator_ids = this.operators
      .filter((o) => this.data.operators.some((_o) => _o._id === o._id))
      .map((o) => o._id);

    if (this.isEdit) {
      this.modelId = null;
      this.setObjects(this.data.records);
      this.setBoundary();
      this.setOtherBoundaries();
    }
  }

  ngAfterViewInit() {
    if (!this.isEdit) {
      return;
    }

    const rasters = [];

    for (let imagerySet of this.imagerySets) {
      const layer = new TileLayer({
        visible: imagerySet.value == this.imagerySet,
        preload: Infinity,
        source: new BingMaps({
          key: environment.bingKey,
          imagerySet: imagerySet.value,
        }),
      });

      layer.imagerySet = imagerySet.value;
      rasters.push(layer);
    }

    this.view = new View({
      center: [0, 0],
      zoom: 18,
    });

    this.geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    const accuracyFeature = new Feature();

    this.geolocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });

    this.geolocation.on('change:position', () => {
      this.locateMe();
    });

    this.geolocation.on('error', (e) => {
      console.error(e);
    });

    this.positionFeature = new Feature();

    this.positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    const vector = new VectorLayer({
      source: new VectorSource({
        features: [accuracyFeature, this.positionFeature],
      }),
    });

    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features: [],
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.obj = new VectorLayer({
      source: geojson,
      style: new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: [0, 0, 255, 0.75] }),
          stroke: new Stroke({
            color: [255, 255, 255, 0.75],
            width: 2,
          }),
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.5)',
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    });

    this.drawSource = new VectorSource();

    this.map = new Map({
      target: this.mapEl.nativeElement,
      layers: [
        ...rasters,
        vector,
        this.obj,
        this.boundary,
        this.otherBoundaries,
      ],
      view: this.view,
      interactions: defaultInteractions({
        onFocusOnly: true,
      }),
    });

    const modify = new Modify({ source: this.drawSource });
    this.map.addInteraction(modify);

    this.view.setZoom(14);
  }

  drawBoundaries() {
    this.drawSource.clear();
    this.drawing = true;

    this.draw = new Draw({
      source: this.drawSource,
      type: 'Polygon',
    });

    this.obj.setSource(this.drawSource);

    this.draw.on('drawend', () => {
      setTimeout(() => {
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);

        const coordinates = this.drawSource
          .getFeatures()[0]
          .getGeometry()
          .transform('EPSG:3857', 'EPSG:4326')
          .getCoordinates();
        this.data.geometry = { type: 'Polygon', coordinates };

        if (this.modelId) {
          this.getIntersection();
        }

        this.drawing = false;

        this.drawSource
          .getFeatures()[0]
          .getGeometry()
          .transform('EPSG:4326', 'EPSG:3857');
      }, 0);
    });

    this.map.addInteraction(this.draw);
    this.snap = new Snap({ source: this.drawSource });
    this.map.addInteraction(this.snap);
  }

  assignRecordsToProject() {
    this.api
      .assignRecords(this.id, this.selectedObjects, this.modelId)
      .subscribe((response) => {
        this.api.updateProject(this.data).subscribe();
      });
  }

  changeImagerySet() {
    for (let layer of this.map.getLayers().array_) {
      if (layer.imagerySet) {
        layer.setVisible(layer.imagerySet == this.imagerySet);
      }
    }
  }

  locateMe() {
    const coordinates = this.geolocation.getPosition();
    this.positionFeature.setGeometry(
      coordinates ? new Point(coordinates) : null
    );

    if (coordinates && !this.centered && !this.data?.geometry) {
      this.view.setCenter(coordinates);
      this.centered = true;
    }
  }

  setObjects(features: any[]) {
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features: features.map((r: any) => {
        return { type: 'Feature', geometry: r.geometry };
      }),
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.obj.setSource(geojson);
  }

  setBoundary() {
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      features: this.data?.geometry ? [this.data.geometry] : [],
    };

    const geojson = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    });

    this.boundary.setSource(geojson);

    if (this.data?.geometry) {
      this.view.fit(geojson.getExtent());
    }
  }

  setOtherBoundaries() {
    this.api.allProjects('name', 'asc').subscribe((projects) => {
      const geojsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326',
          },
        },
        features: projects
          .filter((project) => project._id != this.id && project.geometry)
          .map((project) => {
            return {
              type: 'Feature',
              geometry: project.geometry,
              properties: {
                name: project.name,
              },
            };
          }),
      };

      const geojson = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });

      this.otherBoundaries.setSource(geojson);
    });
  }

  getIntersection() {
    this.api
      .getIntersection(this.modelId, this.data._id, this.data.geometry)
      .subscribe((response) => {
        this.setObjects(response);
        this.setBoundary();

        this.selectedObjects = response.map((r: any) => r._id);

        if (!this.selectedObjects.length) {
          this.notifier.notify('error', 'Nije pronađen nijedan objekat');
        }
      });
  }

  getOtherBoundariesStyle(feature) {
    return new Style({
      fill: new Fill({
        color: 'transparent',
      }),
      stroke: new Stroke({
        color: 'blue',
        width: 3,
        lineDash: [0.1, 5],
      }),
      text: new Text({
        text: feature.values_.name,
        fill: new Fill({ color: 'blue' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        font: '700 14px sans-serif',
      }),
    });
  }

  ngOnDestroy() {
    if (this.geolocation) {
      this.geolocation.setTracking(false);
    }
  }
}
