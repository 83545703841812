<div class="title">
  <h1>Izvještaji</h1>
</div>
<mat-selection-list
  [multiple]="false"
  class="edit-form elevate mat-elevation-z1"
  [class.working]="working"
>
  <ng-container *ngIf="adm == 'fgu'">
    <mat-list-option (click)="getReport('fgu/1')">
      1. Nekretnine po klasi objekta
    </mat-list-option>
    <mat-list-option (click)="getReport('fgu/2')">
      2. Nekretnine po podklasi nekrentine
    </mat-list-option>
    <mat-list-option (click)="getReport('fgu/3')">
      3. Učinak operatera
    </mat-list-option>
    <mat-list-option (click)="getReport('fgu/4')">
      4. Statistika faktičnog stanja na terenu (objekt postoji na terenu)
    </mat-list-option>
  </ng-container>

  <ng-container *ngIf="adm == 'rgu'">
    <mat-list-option (click)="getReport('rgu/1')">
      1. Zaključeni zapisi
    </mat-list-option>
    <mat-list-option (click)="getReport('rgu/2')">
      2. Zaključeni zapisi (dijelovi)
    </mat-list-option>
    <mat-list-option (click)="getReport('rgu/3')">
      3. Učinak po projektima
    </mat-list-option>
    <mat-list-option (click)="getReport('rgu/4')">
      4. Statistika faktičnog stanja na terenu (objekt postoji na terenu)
    </mat-list-option>
  </ng-container>
</mat-selection-list>
