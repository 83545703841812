<div class="title">
  <a mat-icon-button routerLink=".." color="primary" aria-label="Prikaži sve modele">
    <mat-icon>list</mat-icon>
  </a>
  <h1 *ngIf="isEdit && data">Model: {{ data.name }}</h1>
  <h1 *ngIf="!isEdit">Novi model</h1>
</div>
<form (submit)="onSubmit($event)" class="edit-form elevate mat-elevation-z1">

  <!-- name -->
  <mat-form-field>
    <mat-label>Naziv modela</mat-label>
    <input matInput [(ngModel)]="data.name" type="text" name="name" required [errorStateMatcher]="errorStateMatcher">
    <mat-hint>Dozvoljeni su samo alfanumerički znakovi i donje crte</mat-hint>
    <mat-error *ngIf="errors.name">{{ errors.name[0] }}</mat-error>
  </mat-form-field>

  <!-- is_options_model -->
  <mat-slide-toggle [(ngModel)]="data.is_options_model" (ngModelChange)="onIsOptionsModelChange()" name="data.is_options_model">Model je šifarnik</mat-slide-toggle>

  <!-- has_geometry -->
  <mat-slide-toggle *ngIf="!data.is_options_model" [(ngModel)]="data.has_geometry" name="data.has_geometry">Model sadrži geometriju</mat-slide-toggle>

  <!-- geometry_type -->
  <mat-form-field *ngIf="!data.is_options_model && data.has_geometry">
    <mat-label>Tip geometrije</mat-label>
    <mat-select [(ngModel)]="data.geometry_type" name="geometry_type" required [errorStateMatcher]="errorStateMatcher">
      <mat-option value="Polygon">Poligon</mat-option>
      <mat-option value="Point">Tačka</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.geometry_type">{{ errors.geometry_type[0] }}</mat-error>
  </mat-form-field>

  <!-- parent_id -->
  <mat-form-field *ngIf="!data.is_options_model">
    <mat-label>Glavna veza</mat-label>
    <mat-select [(ngModel)]="data.parent_id" name="parent_id" [errorStateMatcher]="errorStateMatcher">
      <mat-option [value]="null">(bez veze)</mat-option>
      <ng-container *ngFor="let model of store.models">
        <mat-option *ngIf="model._id !== this.id" [value]="model._id">{{ model.name }}</mat-option>
      </ng-container>
    </mat-select>
    <mat-hint>Relacija na roditeljski model</mat-hint>
    <mat-error *ngIf="errors.parent_id">{{ errors.parent_id[0] }}</mat-error>
  </mat-form-field>

  <!-- columns -->
  <mat-label class="accordion-label">Kolone *</mat-label>
  <mat-accordion *ngIf="data.columns.length" multi>
    <mat-expansion-panel *ngFor="let column of data.columns; let i = index">
      <mat-expansion-panel-header>
        <div class="expansion-panel-header">
          <mat-icon *ngIf="columnHasError(i)" color="warn" class="expansion-panel-error" aria-label="Validacijska greška">error</mat-icon>
          <mat-panel-title>{{ column.name ? column.name : '(bez naziva)' }}</mat-panel-title>
          <mat-panel-description>{{ column.type }}</mat-panel-description>
        </div>
      </mat-expansion-panel-header>
      <div class="expansion-panel-fields">

        <!-- column.name -->
        <mat-form-field>
          <mat-label>Interni naziv kolone</mat-label>
          <input matInput [(ngModel)]="column.name" type="text" [name]="'columns.' + i + '.name'" required [errorStateMatcher]="errorStateMatcher">
          <mat-hint>Dozvoljeni su samo alfanumerički znakovi i donje crte</mat-hint>
          <mat-error *ngIf="errors['columns.' + i + '.name']">{{ errors['columns.' + i + '.name'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.label -->
        <mat-form-field>
          <mat-label>Prikazani naziv kolone</mat-label>
          <input matInput [(ngModel)]="column.label" type="text" [name]="'columns.' + i + '.label'" [errorStateMatcher]="errorStateMatcher">
          <mat-hint>Ostavite ovo polje prazno ukoliko želite prikazati interni naziv kolone</mat-hint>
          <mat-error *ngIf="errors['columns.' + i + '.label']">{{ errors['columns.' + i + '.label'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.type -->
        <mat-form-field>
          <mat-label>Vrsta kolone</mat-label>
          <mat-select [(ngModel)]="column.type" [name]="'columns.' + i + '.type'" required [errorStateMatcher]="errorStateMatcher">
            <mat-option value="boolean">Bool</mat-option>
            <mat-option value="double">Broj</mat-option>
            <mat-option value="integer">Cijeli broj</mat-option>
            <mat-option value="file">Slike</mat-option>
            <mat-option value="string">String</mat-option>
            <mat-option value="options">Šifarnik</mat-option>
          </mat-select>
          <mat-error *ngIf="errors['columns.' + i + '.type']">{{ errors['columns.' + i + '.type'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.options -->
        <mat-form-field *ngIf="column.type === 'options'">
          <mat-label>Model šifarnika</mat-label>
          <mat-select [(ngModel)]="column.options" [name]="'columns.' + i + '.options'" required [errorStateMatcher]="errorStateMatcher">
            <ng-container *ngFor="let model of store.models">
              <mat-option *ngIf="model.is_options_model && model._id !== this.id" [value]="model._id">{{ model.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="errors['columns.' + i + '.options']">{{ errors['columns.' + i + '.options'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.options_column -->
        <mat-form-field *ngIf="column.type === 'options' && column.options">
          <mat-label>Ključna kolona za šifarnik</mat-label>
          <mat-select [(ngModel)]="column.options_column" [name]="'columns.' + i + '.options_column'" required [errorStateMatcher]="errorStateMatcher">
            <ng-container *ngIf="store.getModel(column.options)">
              <ng-container *ngFor="let _column of store.getModel(column.options).columns">
                <mat-option *ngIf="_column.type === 'string'" [value]="_column.name">{{ _column.name }}</mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
          <mat-hint>Odabrana kolona će biti prikazana u izborniku</mat-hint>
          <mat-error *ngIf="errors['columns.' + i + '.options_column']">{{ errors['columns.' + i + '.options_column'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.boolean_default -->
        <ng-container *ngIf="column.type === 'boolean'">
          <mat-slide-toggle [(ngModel)]="column.boolean_default" [name]="'columns.' + i + '.options_column'">Uključeno po defaultu (bool)</mat-slide-toggle>
        </ng-container>

        <!-- column.in_listing -->
        <mat-slide-toggle [(ngModel)]="column.in_listing" [name]="'columns.' + i + '.in_listing'">Prikaži u tabeli zapisa</mat-slide-toggle>

        <!-- column.is_hidden -->
        <mat-slide-toggle [(ngModel)]="column.is_hidden" [name]="'columns.' + i + '.is_hidden'">Sakrij kolonu (za operatore)</mat-slide-toggle>

        <!-- column.is_locked -->
        <mat-slide-toggle [(ngModel)]="column.is_locked" [name]="'columns.' + i + '.is_locked'">Zaključaj kolonu (za operatore)</mat-slide-toggle>

        <!-- column.description -->
        <mat-form-field>
          <mat-label>Opis kolone</mat-label>
          <input matInput [(ngModel)]="column.description" type="text" [name]="'columns.' + i + '.description'" [errorStateMatcher]="errorStateMatcher">
          <mat-error *ngIf="errors['columns.' + i + '.description']">{{ errors['columns.' + i + '.description'][0] }}</mat-error>
        </mat-form-field>

        <!-- column.validation -->
        <mat-label class="accordion-label">Validacija</mat-label>
        <mat-accordion *ngIf="column.validation.length" multi>
          <mat-expansion-panel *ngFor="let validation of column.validation; let j = index">
            <mat-expansion-panel-header>
              <div class="expansion-panel-header">
                <mat-icon *ngIf="validationHasError(i, j)" color="warn" class="expansion-panel-error" aria-label="Validacijska greška">error</mat-icon>
                <mat-panel-title>{{ validation.rule ? validation.rule : '(prazno)' }}</mat-panel-title>
              </div>
            </mat-expansion-panel-header>
            <div class="expansion-panel-fields">

              <!-- validation.rule -->
              <mat-form-field>
                <mat-label>Pravilo</mat-label>
                <input matInput [(ngModel)]="column.validation[j].rule" type="text" [name]="'columns.' + i + '.validation.' + j + '.rule'" required [errorStateMatcher]="errorStateMatcher">
                <mat-hint><a href="https://laravel.com/docs/7.x/validation#available-validation-rules" target="_blank" rel="noopener">https://laravel.com/docs/7.x/validation#available-validation-rules</a></mat-hint>
                <mat-error *ngIf="errors['columns.' + i + '.validation.' + j + '.rule']">{{ errors['columns.' + i + '.validation.' + j + '.rule'][0] }}</mat-error>
              </mat-form-field>

              <!-- validation.message -->
              <mat-form-field>
                <mat-label>Poruka u slučaju greške</mat-label>
                <input matInput [(ngModel)]="column.validation[j].message" type="text" [name]="'columns.' + i + '.validation.' + j + '.message'" required [errorStateMatcher]="errorStateMatcher">
                <mat-error *ngIf="errors['columns.' + i + '.validation.' + j + '.message']">{{ errors['columns.' + i + '.validation.' + j + '.message'][0] }}</mat-error>
              </mat-form-field>

            </div>
            <mat-action-row>
              <button mat-icon-button *ngIf="j > 0" (click)="moveValidation(column, j, j - 1)" type="button" aria-label="Pomjeri gore">
                <mat-icon>arrow_drop_up</mat-icon>
              </button>
              <button mat-icon-button *ngIf="j < column.validation.length - 1" (click)="moveValidation(column, j, j + 1)" type="button" aria-label="Pomjeri dole">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <button mat-button (click)="column.validation.splice(j, 1)" color="warn" type="button">Obriši validaciju</button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons">
          <button mat-stroked-button (click)="addValidation(column)" color="primary" type="button">Dodaj validaciju</button>
        </div>

      </div>
      <mat-action-row>
        <button mat-icon-button *ngIf="i > 0" (click)="moveColumn(i, i - 1)" type="button" aria-label="Pomjeri gore">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <button mat-icon-button *ngIf="i < data.columns.length - 1" (click)="moveColumn(i, i + 1)" type="button" aria-label="Pomjeri dole">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <button mat-button (click)="data.columns.splice(i, 1)" color="warn" type="button">Obriši kolonu</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-error *ngIf="errors.columns" class="columns-error">{{ errors.columns[0] }}</mat-error>
  <div class="buttons">
    <button mat-stroked-button (click)="addColumn()" color="primary" type="button">Dodaj kolonu</button>
  </div>

  <div class="buttons buttons--component">
    <button mat-raised-button [disabled]="api.isLoading" color="primary">{{ isEdit ? 'Snimi' : 'Kreiraj' }}</button>
    <button *ngIf="isEdit" mat-raised-button (click)="delete()" [disabled]="api.isLoading" color="warn" type="button">Obriši</button>
  </div>

</form>
