import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  modelId: string;
  fk: string;
  ok: string;
  geojson: string;
  errors: any = {};
  errorStateMatcher = new ErrorStateMatcher();
  modelError: string;
  reader = new FileReader();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public api: ApiService,
    public store: StoreService
  ) {
    this.errorStateMatcher.isErrorState = (
      control: FormControl,
      form: NgForm
    ): boolean => {
      const name = Object.keys(form.controls).find(
        (name) => form.controls[name] === control
      );

      if (name === 'geojson') {
        return this.errors.geojson || this.modelError;
      }

      return !name || this.errors[name];
    };

    this.reader.addEventListener('loadend', (e: any) => {
      this.geojson = e.srcElement.result;
    });
  }

  isParentModel(modelId: string): boolean {
    const model = this.store.models.find((m) => m._id === modelId);
    return model && !model.parent_id;
  }

  onSubmit(e: any) {
    e && e.preventDefault();

    this.api
      .importRecords(this.modelId, this.fk, this.ok, this.geojson)
      .subscribe(
        (r: any) => {
          this.router.navigate(['records', 'model', this.modelId]);
        },
        (e: HttpErrorResponse) => {
          this.errors = e.error.errors;
          this.modelError = null;

          for (let key in this.errors) {
            if (key !== 'modelId' && key !== 'geojson') {
              this.modelError = this.errors[key][0];
            }
          }
        }
      );
  }

  onDragOver(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  onDrop(e: any) {
    e.preventDefault();

    if (e.dataTransfer.items.length === 1) {
      const file = e.dataTransfer.items[0].getAsFile();
      this.reader.readAsText(file);
    }
  }
}
