import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, AfterViewInit } from '@angular/core';
import { IndexComponent } from '../index/index.component';
import { StringHelper } from 'src/app/helpers/string.helper';

@Component({
  selector: 'app-project-groups',
  templateUrl: './project-groups.component.html',
  styleUrls: ['./project-groups.component.scss'],
})
export class ProjectGroupsComponent extends IndexComponent implements AfterViewInit {

  protected apiSlug = 'ProjectGroup';

  constructor(
    public api: ApiService,
    public auth: AuthService,
    public str: StringHelper,
  ) {
    super(api);

    this.displayedColumns = ['name', 'projects', 'created_at', 'updated_at'];
    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovu grupu?';
  }

}
