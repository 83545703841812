<div *ngIf="parentId" class="title">
  <a
    mat-icon-button
    [routerLink]="['/records', parentId]"
    color="primary"
    aria-label="Nazad"
  >
    <mat-icon>chevron_left</mat-icon>
  </a>
  <h1 *ngIf="isEdit && data && model">
    Zapis {{ data._id }} u modelu {{ model.name }}
  </h1>
  <h1 *ngIf="!isEdit && model">Novi zapis u model {{ model.name }}</h1>
</div>

<div *ngIf="!parentId" class="title">
  <a
    mat-icon-button
    [routerLink]="backRouterLink"
    color="primary"
    aria-label="Prikaži sve zapise"
  >
    <mat-icon>list</mat-icon>
  </a>
  <h1 *ngIf="isEdit && data && model">
    Zapis {{ data._id }} u modelu {{ model.name }}
  </h1>
  <h1 *ngIf="!isEdit && model">Novi zapis u model {{ model.name }}</h1>
</div>

<form
  *ngIf="model && data"
  (submit)="onSubmit($event)"
  class="edit-form elevate mat-elevation-z1"
>
  <!-- map -->
  <div *ngIf="model.has_geometry" class="map">
    <div #map class="map__ol" tabindex="0">
      <mat-form-field class="mat-elevation-z2">
        <mat-label>Podloga</mat-label>
        <mat-select
          [(ngModel)]="imagerySet"
          (ngModelChange)="changeImagerySet()"
          name="imagerySet"
          required
        >
          <mat-option
            *ngFor="let imagerySet of imagerySets"
            [value]="imagerySet.value"
            >{{ imagerySet.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-error *ngIf="errors.geometry">{{ errors.geometry[0] }}</mat-error>
    <div class="map__buttons">
      <button
        mat-raised-button
        (click)="drawObject()"
        [disabled]="drawing"
        color="primary"
        type="button"
      >
        {{
          model.geometry_type == "Polygon" ? "Skiciraj objekt" : "Označi objekt"
        }}
      </button>
      <span *ngIf="area" [innerHtml]="area"></span>
    </div>
  </div>

  <!-- status -->
  <mat-form-field
    *ngIf="!model.parent_id && !model.is_options_model && auth.isRole('admin')"
  >
    <mat-label>Status</mat-label>
    <mat-select
      [(ngModel)]="data.status"
      name="status"
      required
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option value="open">Otvoren</mat-option>
      <mat-option value="review">U pregledu</mat-option>
      <mat-option value="closed">Zaključen</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.status">{{ errors.status }}</mat-error>
  </mat-form-field>

  <!-- project_id -->
  <mat-form-field
    *ngIf="!model.is_options_model && auth.isAnyRole(['moderator', 'admin'])"
  >
    <mat-label>Projekt</mat-label>
    <mat-select
      [(ngModel)]="data.project_id"
      name="project_id"
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option [value]="null">(bez projekta)</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project._id">{{
        project.name
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errors.project_id">{{ errors.project_id }}</mat-error>
  </mat-form-field>

  <!-- parent -->
  <mat-form-field *ngIf="model.parent_id">
    <mat-label>Veza na model {{ model.parent.name }}</mat-label>
    <input
      matInput
      [(ngModel)]="data.parent_id"
      type="text"
      name="parent_id"
      required
      [disabled]="parentId"
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-hint *ngIf="data.parent_id"
      ><a [routerLink]="['/records', data.parent_id]"
        >Idi na zapis {{ data.parent_id }}</a
      ></mat-hint
    >
    <mat-error *ngIf="errors.parent_id">{{ errors.parent_id }}</mat-error>
  </mat-form-field>

  <!-- columns -->
  <ng-container *ngFor="let column of model.columns; let i = index">
    <ng-container
      *ngIf="!column.is_hidden || auth.isAnyRole(['moderator', 'admin'])"
    >
      <!-- boolean -->
      <ng-container *ngIf="column.type === 'boolean' && isVisible(column)">
        <mat-slide-toggle
          [(ngModel)]="data.data[column.name]"
          [name]="'data.' + column.name"
          [class.dirty]="
            auth.isRole('moderator') &&
            initialData &&
            initialData.data[column.name] != data.data[column.name]
          "
          [disabled]="isLocked(column)"
          >{{ column.label ? column.label : column.name }}</mat-slide-toggle
        >
        <mat-hint *ngIf="column.description">{{ column.description }}</mat-hint>
      </ng-container>

      <!-- double -->
      <mat-form-field
        *ngIf="column.type === 'double' && isVisible(column)"
        [class.dirty]="
          auth.isRole('moderator') &&
          initialData &&
          initialData.data[column.name] != data.data[column.name]
        "
      >
        <mat-label>{{ column.label ? column.label : column.name }}</mat-label>
        <input
          matInput
          [(ngModel)]="data.data[column.name]"
          type="number"
          step="0.01"
          [name]="'data.' + column.name"
          [required]="isRequired(column)"
          [disabled]="isLocked(column)"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-hint *ngIf="column.description">{{ column.description }}</mat-hint>
        <mat-error *ngIf="errors['data.' + column.name]">{{
          errors["data." + column.name][0]
        }}</mat-error>
      </mat-form-field>

      <!-- file -->
      <div *ngIf="column.type === 'file' && isVisible(column)">
        <mat-hint>{{ column.label ? column.label : column.name }}</mat-hint>
        <div *ngIf="data.data[column.name]" class="file-preview">
          <div *ngFor="let file of data.data[column.name]; let j = index">
            <a [href]="'http://storage.rgu.local/' + file.substring(file.lastIndexOf('/') + 1)" target="_blank">
              <img [src]="'http://storage.rgu.local/' + file.substring(file.lastIndexOf('/') + 1)" />
            </a>                    
            <button
              mat-mini-fab
              (click)="data.data[column.name].splice(j, 1)"
              color="warn"
              type="button"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="file-buttons">
          <label class="file-label">
            <input
              (change)="onFileChange($event, column.name)"
              type="file"
              accept="image/*"
              [id]="'file.' + column.name"
              multiple
            />
            <label
              mat-raised-button
              (click)="drawObject()"
              [for]="'file.' + column.name"
              color="primary"
              type="button"
              >Galerija</label
            >
          </label>
        </div>
        <mat-error
          *ngIf="errors['data.' + column.name]"
          style="margin-top: 10px"
          >{{ errors["data." + column.name][0] }}</mat-error
        >
      </div>

      <!-- integer -->
      <mat-form-field
        *ngIf="column.type === 'integer' && isVisible(column)"
        [class.dirty]="
          auth.isRole('moderator') &&
          initialData &&
          initialData.data[column.name] != data.data[column.name]
        "
      >
        <mat-label>{{ column.label ? column.label : column.name }}</mat-label>
        <input
          matInput
          [(ngModel)]="data.data[column.name]"
          type="number"
          [name]="'data.' + column.name"
          [required]="isRequired(column)"
          [disabled]="isLocked(column)"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-hint *ngIf="column.description">{{ column.description }}</mat-hint>
        <mat-error *ngIf="errors['data.' + column.name]">{{
          errors["data." + column.name][0]
        }}</mat-error>
      </mat-form-field>

      <!-- options -->
      <mat-form-field
        *ngIf="column.type === 'options' && isVisible(column)"
        [class.dirty]="
          auth.isRole('moderator') &&
          initialData &&
          initialData.data[column.name] != data.data[column.name]
        "
      >
        <mat-label>{{ column.label ? column.label : column.name }}</mat-label>
        <mat-select
          [(ngModel)]="data.data[column.name]"
          [name]="'data.' + column.name"
          [required]="isRequired(column)"
          [disabled]="isLocked(column)"
          [errorStateMatcher]="errorStateMatcher"
        >
          <ng-container *ngIf="options[column.options]">
            <mat-option
              *ngFor="let option of options[column.options]"
              [value]="option._id"
              >{{ option[column.options_column] }}</mat-option
            >
          </ng-container>
        </mat-select>
        <mat-hint *ngIf="column.description">{{ column.description }}</mat-hint>
        <mat-error *ngIf="errors['data.' + column.name]">{{
          errors["data." + column.name][0]
        }}</mat-error>
      </mat-form-field>

      <!-- string -->
      <mat-form-field
        *ngIf="column.type === 'string' && isVisible(column)"
        [class.dirty]="
          auth.isRole('moderator') &&
          initialData &&
          initialData.data[column.name] != data.data[column.name]
        "
      >
        <mat-label>{{ column.label ? column.label : column.name }}</mat-label>
        <input
          matInput
          [(ngModel)]="data.data[column.name]"
          type="text"
          [name]="'data.' + column.name"
          [required]="isRequired(column)"
          [disabled]="isLocked(column)"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-hint *ngIf="column.description">{{ column.description }}</mat-hint>
        <mat-error *ngIf="errors['data.' + column.name]">{{
          errors["data." + column.name][0]
        }}</mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <!-- comments -->
  <div *ngIf="data.comments.length">
    <mat-label>Napomene</mat-label>
    <div *ngFor="let comment of data.comments" class="comment">
      {{ comment }}
    </div>
  </div>

  <!-- comment -->
  <mat-form-field>
    <mat-label>Dodaj napomenu</mat-label>
    <textarea
      matInput
      [(ngModel)]="data.comment"
      rows="4"
      name="comment"
      [errorStateMatcher]="errorStateMatcher"
    ></textarea>
    <mat-hint>Vaša napomena će biti dodana nakon snimanja ovog zapisa</mat-hint>
    <mat-error *ngIf="errors.comment">{{ errors.comment[0] }}</mat-error>
  </mat-form-field>

  <!-- children -->
  <ng-container *ngIf="isEdit && model.children.length">
    <ng-container *ngFor="let childModel of model.children">
      <mat-label class="accordion-label"
        >Veza na model {{ childModel.name }}</mat-label
      >
      <div *ngIf="data.children.length" class="filter">
        <strong>Sortiraj:</strong>
        <ng-container *ngFor="let column of childModel.columns">
          <span *ngIf="column.in_listing">
            {{ column.label ? column.label : column.name }}
            <mat-icon (click)="sortChildren(column.name, 'asc')">
              arrow_drop_down
            </mat-icon>
            <mat-icon (click)="sortChildren(column.name, 'desc')">
              arrow_drop_up
            </mat-icon>
          </span>
        </ng-container>
      </div>
      <mat-selection-list *ngIf="data.children.length" [multiple]="false">
        <mat-list-option
          *ngFor="let childRecord of data.children"
          [value]="childRecord"
          (click)="editChild(childRecord)"
        >
          <span class="id">{{ childRecord._id }}</span>
          <ng-container *ngFor="let column of childModel.columns">
            <span *ngIf="column.in_listing">
              {{ column.label ? column.label : column.name }}:
              {{ displayChildColumn(column, childRecord) }}
            </span>
          </ng-container>
        </mat-list-option>
      </mat-selection-list>
      <div class="buttons">
        <button
          mat-stroked-button
          (click)="addChild(childModel)"
          color="primary"
          type="button"
        >
          Dodaj zapis
        </button>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="buttons"
    [class.buttons--component]="isEdit && model.children.length"
  >
    <button mat-raised-button [disabled]="api.isLoading" color="primary">
      {{ isEdit ? "Snimi" : "Kreiraj" }}
    </button>
    <button
      mat-raised-button
      (click)="submitAndCreateNew()"
      [disabled]="api.isLoading"
      color="primary"
      type="button"
    >
      {{ isEdit ? "Snimi i dodaj novi" : "Kreiraj i dodaj novi" }}
    </button>
    <button
      *ngIf="isEdit && !data.parent_id && auth.isRole('operator')"
      mat-raised-button
      (click)="updateStatus('review')"
      [disabled]="api.isLoading"
      color="accent"
      type="button"
    >
      Pošalji na pregled
    </button>
    <button
      *ngIf="isEdit && initialData && auth.isAnyRole(['moderator', 'admin'])"
      mat-raised-button
      (click)="fillInitialValues()"
      [disabled]="api.isLoading"
      color="primary"
      type="button"
    >
      Popuni prazna polja inicijalnim vrijednostima
    </button>
    <button
      *ngIf="
        isEdit &&
        !data.parent_id &&
        auth.isRole('moderator') &&
        data.status === 'review'
      "
      mat-raised-button
      (click)="updateStatus('open')"
      [disabled]="api.isLoading"
      color="accent"
      type="button"
    >
      Vrati na doradu
    </button>
    <button
      *ngIf="
        isEdit &&
        !data.parent_id &&
        auth.isRole('moderator') &&
        data.status === 'review'
      "
      mat-raised-button
      (click)="updateStatus('closed')"
      [disabled]="api.isLoading"
      color="accent"
      type="button"
    >
      Zaključi
    </button>
    <button
      *ngIf="
        isEdit &&
        (!auth.isRole('operator') || data.author_id === auth.getUserId())
      "
      mat-raised-button
      (click)="delete()"
      [disabled]="api.isLoading"
      color="warn"
      type="button"
    >
      Obriši
    </button>
    <div *ngIf="prev || next" class="buttons__right">
      <a
        *ngIf="prev"
        mat-raised-button
        [routerLink]="prev.routerLink"
        [queryParams]="prev.queryParams"
        color="primary"
        type="button"
      >
        Prethodni
      </a>
      <a
        *ngIf="next"
        mat-raised-button
        [routerLink]="next.routerLink"
        [queryParams]="next.queryParams"
        color="primary"
        type="button"
      >
        Idući
      </a>
    </div>
  </div>
</form>
