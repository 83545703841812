<a mat-button [routerLink]="['/map']" routerLinkActive="active" (click)="drawerClose.emit()">Mapa</a>
<mat-divider *ngIf="store.models"></mat-divider>
<ng-container *ngFor="let model of store.models">
  <a *ngIf="auth.isRole('admin') || (auth.isRole('moderator') && !model.is_options_model) || (!model.is_options_model && !model.parent_id)" mat-button [routerLink]="['/records', 'model', model._id]" routerLinkActive="active" (click)="drawerClose.emit()">{{ model.name }}</a>
</ng-container>
<mat-divider *ngIf="auth.isAnyRole(['moderator', 'admin'])"></mat-divider>
<a mat-button *ngIf="auth.isAnyRole(['moderator', 'admin'])" [routerLink]="['/users']" routerLinkActive="active" (click)="drawerClose.emit()">Korisnici</a>
<a mat-button *ngIf="auth.isRole('admin')" [routerLink]="['/models']" routerLinkActive="active" (click)="drawerClose.emit()">Modeli</a>
<a mat-button *ngIf="auth.isAnyRole(['moderator', 'admin'])" [routerLink]="['/projects']" routerLinkActive="active" (click)="drawerClose.emit()">Projekti</a>
<a mat-button *ngIf="auth.isRole('admin')" [routerLink]="['/project-groups']" routerLinkActive="active" (click)="drawerClose.emit()">Grupe projekata</a>
<a mat-button *ngIf="auth.isAnyRole(['moderator', 'admin'])" [routerLink]="['/import']" routerLinkActive="active" (click)="drawerClose.emit()">Import</a>
<a mat-button *ngIf="auth.isAnyRole(['moderator', 'admin'])" [routerLink]="['/export']" routerLinkActive="active" (click)="drawerClose.emit()">Eksport</a>
<a mat-button *ngIf="auth.isRole('admin')" [routerLink]="['/reports']" routerLinkActive="active" (click)="drawerClose.emit()">Izvještaji</a>
