import { ApiService } from 'src/app/services/api.service';
import { Column } from 'src/app/models/column';
import { Component } from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { Model } from 'src/app/models/model';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
})
export class ModelComponent extends EditComponent {

  public data: Model;

  protected apiSlug = 'Model';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public api: ApiService,
    public store: StoreService,
  ) {
    super(route, router, api);

    const blank: Model = {
      name: '',
      is_options_model: false,
      has_geometry: false,
      geometry_type: 'Polygon',
      parent_id: null,
      columns: [],
    };

    this.fill(blank);

    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovaj model?';
  }

  addColumn() {
    const column: Column = {
      name: '',
      label: '',
      type: 'string',
      in_listing: false,
      is_hidden: false,
      is_locked: false,
      description: '',
      validation: []
    };

    this.data.columns.push(column);
    this.cleanColumnErrors();
  }

  addValidation(column: Column) {
    column.validation.push({
      rule: '',
      message: ''
    });
  }

  moveColumn(index: number, newIndex: number) {
    this.data.columns[index] = this.data.columns.splice(newIndex, 1, this.data.columns[index])[0];
    this.cleanColumnErrors();
  }

  moveValidation(column: Column, index: number, newIndex: number) {
    column.validation[index] = column.validation.splice(newIndex, 1, column.validation[index])[0];
  }

  columnHasError(index: number): boolean {
    return Object.keys(this.errors).some(name => name.startsWith('columns.' + index));
  }

  validationHasError(columnIndex: Column, index: number): boolean {
    return Object.keys(this.errors).some(name => name.startsWith('columns.' + columnIndex + '.validation.' + index));
  }

  cleanColumnErrors() {
    for (let name in this.errors) {
      if (name.startsWith('columns')) {
        delete this.errors[name];
      }
    }
  }

  onIsOptionsModelChange() {
    this.data.has_geometry = false;
    this.data.parent_id = null;
  }

}
