import { Component, AfterViewInit } from '@angular/core';
import { IndexComponent } from '../index/index.component';
import { ApiService } from 'src/app/services/api.service';
import { StringHelper } from 'src/app/helpers/string.helper';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelsComponent extends IndexComponent implements AfterViewInit {

  protected apiSlug = 'Model';

  constructor(
    public api: ApiService,
    public str: StringHelper
  ) {
    super(api);

    this.displayedColumns = ['name', 'is_options_model', 'has_geometry', 'parent_id', 'created_at', 'updated_at'];
    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovaj model?';
  }

}
