import { AuthService } from 'src/app/services/auth.service';
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Output() drawerToggle = new EventEmitter();

  constructor(public auth: AuthService) { }

}
