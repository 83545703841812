import { AnonymousGuard } from './guards/anonymous.guard';
import { AuthGuard } from './guards/auth.guard';
import { ExportComponent } from './components/export/export.component';
import { ImportComponent } from './components/import/import.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MainComponent } from './components/main/main.component';
import { MapComponent } from './components/map/map.component';
import { ModelComponent } from './components/model/model.component';
import { ModelsComponent } from './components/models/models.component';
import { NgModule } from '@angular/core';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectGroupComponent } from './components/project-group/project-group.component';
import { ProjectGroupsComponent } from './components/project-groups/project-groups.component';
import { RecordComponent } from './components/record/record.component';
import { RecordsComponent } from './components/records/records.component';
import { ReportsComponent } from './components/reports/reports.component';
import { RoleGuard } from './guards/role.guard';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  {
    path: '', component: MainComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: '/map', pathMatch: 'full' },
      { path: 'users', component: UsersComponent, canActivate: [RoleGuard], data: { roles: ['admin', 'moderator'] } },
      { path: 'users/:id', component: UserComponent, canActivate: [RoleGuard], data: { roles: ['admin', 'moderator'] } },
      { path: 'models', component: ModelsComponent, canActivate: [RoleGuard], data: { roles: ['admin'] } },
      { path: 'models/:id', component: ModelComponent, canActivate: [RoleGuard], data: { roles: ['admin'] } },
      { path: 'projects', component: ProjectsComponent, canActivate: [RoleGuard], data: { roles: ['admin', 'moderator'] } },
      { path: 'projects/:id', component: ProjectComponent, canActivate: [RoleGuard], data: { roles: ['admin', 'moderator'] } },
      { path: 'project-groups', component: ProjectGroupsComponent, canActivate: [RoleGuard], data: { roles: ['admin'] } },
      { path: 'project-groups/:id', component: ProjectGroupComponent, canActivate: [RoleGuard], data: { roles: ['admin'] } },
      { path: 'records/model/:modelId', component: RecordsComponent },
      { path: 'records/model/:modelId/new', component: RecordComponent },
      { path: 'records/:id', component: RecordComponent },
      { path: 'import', component: ImportComponent },
      { path: 'export', component: ExportComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'map', component: MapComponent },
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [AnonymousGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
