import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, AfterViewInit } from '@angular/core';
import { IndexComponent } from '../index/index.component';
import { StringHelper } from 'src/app/helpers/string.helper';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent extends IndexComponent implements AfterViewInit {

  protected apiSlug = 'Project';

  constructor(
    public api: ApiService,
    public auth: AuthService,
    public str: StringHelper,
  ) {
    super(api);

    this.displayedColumns = ['name', 'project_group', 'moderator', 'operators', 'created_at', 'updated_at'];
    this.deleteConfirmationMessage = 'Da li ste sigurni da želite obrisati ovaj projekt?';
  }

}
