// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { NotifierModule } from 'angular-notifier';

// Environment
import { environment } from 'src/environments/environment';

// Interceptors
import { ApiInterceptor } from './interceptors/api.interceptor';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntlBs } from './providers/mat-paginator-intl-bs';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

// Components
import { AppComponent } from './app.component';
import { ExportComponent } from './components/export/export.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ImportComponent } from './components/import/import.component';
import { MainComponent } from './components/main/main.component';
import { MapComponent } from './components/map/map.component';
import { ModelComponent } from './components/model/model.component';
import { ModelsComponent } from './components/models/models.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectGroupComponent } from './components/project-group/project-group.component';
import { ProjectGroupsComponent } from './components/project-groups/project-groups.component';
import { RecordComponent } from './components/record/record.component';
import { RecordsComponent } from './components/records/records.component';
import { ReportsComponent } from './components/reports/reports.component';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from './components/users/users.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
  declarations: [
    AppComponent,
    ExportComponent,
    LoginComponent,
    LogoutComponent,
    ImportComponent,
    MainComponent,
    MapComponent,
    ModelComponent,
    ModelsComponent,
    ProjectComponent,
    ProjectsComponent,
    ProjectGroupComponent,
    ProjectGroupsComponent,
    RecordComponent,
    RecordsComponent,
    ReportsComponent,
    UserComponent,
    UsersComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('token'),
        whitelistedDomains: environment.jwt.whitelistedDomains,
      },
    }),
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MomentModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
        },
      },
      behaviour: {
        stacking: 5,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlBs,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
