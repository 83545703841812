<div class="title">
  <h1 *ngIf="model">Zapisi u modelu {{ model.name }}</h1>
  <a *ngIf="auth.getUserProjectId() || auth.isAnyRole(['admin', 'moderator'])" mat-fab routerLink="new" color="primary" aria-label="Kreiraj novi zapis">
    <mat-icon>add</mat-icon>
  </a>
</div>
<div *ngIf="model" class="filters">
  <mat-form-field *ngIf="model && !model.is_options_model && auth.isAnyRole(['admin', 'moderator'])">
    <mat-label>Projekt</mat-label>
    <mat-select [(ngModel)]="projectId" (ngModelChange)="index()" name="status">
      <mat-option [value]="null">Svi projekti</mat-option>
      <mat-option value="without">Bez projekta</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project._id">{{ project.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="model && !model.is_options_model">
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="status" (ngModelChange)="index()" name="status">
      <mat-option [value]="null">Svi projekti</mat-option>
      <mat-option value="open">Otvoren</mat-option>
      <mat-option value="review">U pregledu</mat-option>
      <mat-option value="closed">Zaključen</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngFor="let column of model.columns">
    <mat-form-field *ngIf="column.in_listing && column.options && options[column.name]">
      <mat-label>{{ column.label ? column.label : column.name }}</mat-label>
      <mat-select [(ngModel)]="dataFilters[column.name]" (ngModelChange)="index()" [name]="'filter_' + column.name">
        <mat-option [value]="null">Sve</mat-option>
        <mat-option *ngFor="let option of options[column.name] | keyvalue" [value]="option.key">{{ option.value }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

</div>
<div class="container mat-elevation-z1">
  <div class="table-responsive" [class.disabled]="isLoadingResults">
    <table *ngIf="model" mat-table [dataSource]="data" multiTemplateDataRows matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear>
      <ng-container *ngFor="let column of model.columns" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef>{{ column.label ? column.label : column.name }}</th>
        <td mat-cell *matCellDef="let row">{{ options[column.name] ? options[column.name][row.data[column.name]] : row.data[column.name] }}</td>
      </ng-container>
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef>Projekt</th>
        <td mat-cell *matCellDef="let row">{{ row.project ? row.project.name : '-' }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ getStatusName(row.status) }}</td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kreiran</th>
        <td mat-cell *matCellDef="let row">{{ row.created_at | amDateFormat:'DD.MM.YYYY u HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ažuriran</th>
        <td mat-cell *matCellDef="let row">{{ row.updated_at | amDateFormat:'DD.MM.YYYY u HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div *ngIf="auth.isRole('admin') || (auth.isRole('moderator') && row.status != 'closed' && (!row.project_id || moderatorHasPermissions(row.project_id))) || (auth.isRole('operator') && row.status == 'open')" class="row-options" [@optionsExpand]="row === expandedRow ? 'expanded' : 'collapsed'">
            <a mat-raised-button color="primary" [routerLink]="['../..', row._id]">Izmijeni</a>
            <button *ngIf="!auth.isRole('operator') || row.author_id === auth.getUserId()" mat-raised-button color="warn" (click)="deleteRow(row._id)">Obriši</button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="expandedRow = expandedRow === row ? null : row" class="main" [class.expanded]="expandedRow === row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['options']" class="options"></tr>
    </table>
  </div>
  <mat-paginator [length]="resultsLength" pageSize="50" [pageSizeOptions]="limit"></mat-paginator>
</div>
