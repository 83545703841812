<mat-drawer-container hasBackdrop>
  <mat-drawer #drawer mode="over">
    <app-sidebar (drawerClose)="drawer.close()"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content>
    <app-header (drawerToggle)="drawer.toggle()"></app-header>
    <div class="main">
      <router-outlet></router-outlet>
      <!-- <div class="footer"></div> -->
    </div>
  </mat-drawer-content>
</mat-drawer-container>
