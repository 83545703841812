<form (submit)="onSubmit($event)" class="mat-elevation-z2">
  <span *ngIf="error" class="error">{{ error }}</span>
  <mat-form-field class="form-field">
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="email" (ngModelChange)="error = null" type="email" name="email">
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>Lozinka</mat-label>
    <input matInput [(ngModel)]="password" (ngModelChange)="error = null" type="password" name="password">
  </mat-form-field>
  <button mat-raised-button [disabled]="!isValid() || api.isLoading" color="primary" class="login-button">Prijavi se</button>
</form>
